export const getQualificationParams = (data) => {

    const params = {};

    if (data.qualifiedOnly) {
        Object.assign(params, {
            qualified_only_selection_mode: data.selectionMode,
        });
    }
    
    if (data.selectionMode === "lists" && data.qualifiedOnly) {
        Object.assign(params, {
            smart_list_ids: data.lists.smart.ids,
            club_list_ids: data.lists.club.ids,
            user_list_ids: data.lists.user.ids,
            smart_lists: data.lists.smart.items.map(list => ({list_id: list.id, list})),
            club_lists: data.lists.club.items.map(list => ({list_id: list.id, list})),
            user_lists: data.lists.user.items.map(list => ({list_id: list.id, list})),
        });
    }

    if (data.selectionMode === "filters" && data.qualifiedOnly) {
        params.filters = {
            schools: data.filters.schools,
            majors: data.filters.majors,
            class_years: data.filters.classYears,
            gpa: data.filters.gpa,
            clubs: data.filters.clubs,
            current_companies: data.filters.currentCompanies,
            previous_companies: data.filters.previousCompanies,
            verticals: data.filters.verticals,
            gender: data.filters.gender,
            race: data.filters.race,
            veteran: data.filters.veteran,
            lgbtq: data.filters.lgbtq,
            disabled: data.filters.disabled,
            authorized: data.filters.authorized,
        };
    }


    return params;
}; 