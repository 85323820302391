import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import JobBoardLoading from "../components/loading/JobBoardLoading";
import { adminPeopleSearch } from "../utils/typesense";
import AdminDashboardTable from "./AdminDashboardTable";
import { useInView } from "react-intersection-observer";
import AdminFiltersSidebar from "./AdminFiltersSidebar";
import SvgSearch from "../components/icons/SvgSearch";
import AdminPeopleManagementRow from "./AdminPeopleManagementRow";
import {Button} from "../components/atoms/Button";
import { getLateralRecruitingData } from "../api/recruiter/lateral-recruiting";
import useUser from "../hooks/useUser";
import { useSelector } from 'react-redux'

const MAX_PAGE_RESULTS = 20;

export const ADMIN_PEOPLE_MANAGEMENT_TABLE_HEADERS = [
    { key: "actions", label: "Actions", width: "minmax(100px, 0.2fr)", isCentered: true },
    { key: "verification", label: "Verified", width: "minmax(80px, 0.2fr)", isCentered: true },
    { key: "identity", label: "Identity", width: "minmax(250px, 1fr)" },
    { key: "education", label: "Education", width: "minmax(200px, 1fr)" },
    { key: "current_role", label: "Current Role", width: "minmax(250px, 1fr)" },
    { key: "location", label: "Location", width: "minmax(150px, 0.75fr)" },
    { key: "previous_roles", label: "Previous Roles", width: "minmax(300px, 1.5fr)" },
    
];

const initialFilters = {
    classYears: null,
    college: [],
    search: "",
    industry: null,
    roles: null,
    locations: null,
    title: null,
    currentCompany: null,
    previousCompany: null,
    page: 1,
};


const AdminPeopleManagement = () => {
    const { authUser } = useUser();
    const constants = useSelector((state) => state.userReducer.constants);
    const [people, setPeople] = useState([]);
    const [loading, setLoading] = useState(true);
    const [morePeopleLoading, setMorePeopleLoading] = useState(false);
    const [filters, setFilters] = useState(initialFilters);
    const [numOfPages, setNumOfPages] = useState(1);
    const [numItems, setNumItems] = useState(0);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const { ref: bottomRef, inView } = useInView({
        threshold: 0.1,
    });

    const hideMorePeople = filters.page >= numOfPages;

    const INDUSTRY_TAGS = constants.industry_tags.map((tag) => ({
        title: tag,
        value: tag,
    }));
    const SECONDARY_TAGS = constants.secondary_tags.map((tag) => ({
        title: tag,
        value: tag,
    }));
    const LOCATION_TAGS = constants.locations.map((loc) => ({
        title: loc.city,
        value: loc.city,
    }));

    const TITLE_TAGS = [
        { title: "Analyst" },
        { title: "Associate" },
        { title: "Vice President" },
        { title: "Managing Director" },
    ];


    const fetchPeople = useCallback(
        async (filters, page = null, count = MAX_PAGE_RESULTS, setState = true) => {
            const {
                classYears,
                college,
                currentCompany,
                previousCompany,
                search,
                industry,
                roles,
                locations,
                title,
            } = filters;
            const pageToFetch = page ?? filters.page;
            const isFirstPage = pageToFetch === 1;

            if (setState) {
                if (isFirstPage) {
                    setLoading(true);
                } else {
                    setMorePeopleLoading(true);
                }
            }

            const results = await getLateralRecruitingData(authUser, {
                undergraduate_year: classYears,
                school: college,
                current_company: currentCompany,
                previous_company: previousCompany,
                name: search,
                sector: industry,
                role: roles,
                city: locations,
                title: title,
                page: pageToFetch,
                count,
            });

            if (setState) {
                setNumOfPages(results?.num_pages ?? 1);
                setNumItems(results?.num_items ?? 0);
            }

            const newResults = results?.results?.map((result) => result.document) ?? [];

            if (setState) {
                if (isFirstPage) {
                    setLoading(false);
                    setPeople(newResults);
                } else {
                    setPeople((prevPeople) => [...prevPeople, ...newResults]);
                    setMorePeopleLoading(false);
                }
            }
            return newResults;
        },
        [authUser]
    );

    useEffect(() => {
        fetchPeople(filters);
    }, [filters, fetchPeople]);

    const getNextPeopleInfo = () => {
        setFilters((prev) => ({
            ...prev,
            page: prev.page + 1,
        }));
    };

    useEffect(() => {
        if (inView && !loading && !hideMorePeople) {
            setShowLoadMore(true);
        } else {
            setShowLoadMore(false);
        }
    }, [inView, loading, hideMorePeople]);

    const updateFilters = (updates) => {
        setFilters((prev) => ({
            ...prev,
            ...updates,
            page: 1,
        }));
    };

    const handleSearchChange = (event) => {
        updateFilters({ search: event.target.value });
    };

    const handleSetSchool = (result) => {
        updateFilters({ college: !!result ? [result] : [] });
    };

    const setCurrentCompany = (companyId) => {
        updateFilters({ currentCompany: companyId });
    };

    const setPreviousCompany = (companyId) => {
        updateFilters({ previousCompany: companyId });
    };

    const setClassYears = (year) => {
        updateFilters({ classYears: year });
    };

    const updateIndustry = (selectedIndustry) => {
        updateFilters({ industry: selectedIndustry });
    };

    const updateRoles = (selectedRole) => {
        updateFilters({ roles: selectedRole });
    };

    const updateTitle = (selectedTitle) => {
        updateFilters({ title: selectedTitle });
    };

    const updateLocation = (selectedLocation) => {
        updateFilters({ locations: selectedLocation });
    };

    const handleStatusChange = (personId, deactivated) => {
        setPeople((prevPeople) =>
            prevPeople.map((person) => 
                person.id === personId 
                    ? { ...person, deactivated }
                    : person
            )
        );
    };

    return (
        <div className="flex flex-col flex-1 overflow-hidden">
            <div className="flex flex-row flex-1 overflow-hidden">
                <div className="w-[250px] px-[12px] flex flex-col border-r border-slate-200">
                    <AdminFiltersSidebar
                        search={{
                            placeholder: "Search by name...",
                            value: filters.search,
                            onChange: handleSearchChange,
                        }}
                        className="mb-2"
                        school={{
                            schoolSearch: filters.college,
                            setSchool: handleSetSchool,
                        }}
                        selects={[
                            {
                                label: "Class Year",
                                value: filters.classYears,
                                setValue: setClassYears,
                                options: [
                                    { title: "2015" },
                                    { title: "2016" },
                                    { title: "2017" },
                                    { title: "2018" },
                                    { title: "2019" },
                                    { title: "2020" },
                                    { title: "2021" },
                                    { title: "2022" },
                                    { title: "2023" },
                                    { title: "2024" },
                                    { title: "2025" },
                                    { title: "2026" },
                                    { title: "2027" },
                                    { title: "2028" },
                                ],
                                multiSelect: false,
                            },
                            {
                                label: "Industry",
                                value: filters.industry,
                                setValue: updateIndustry,
                                options: INDUSTRY_TAGS,
                                multiSelect: false,
                            },
                            {
                                label: "Vertical",
                                value: filters.roles,
                                setValue: updateRoles,
                                options: SECONDARY_TAGS,
                                multiSelect: false,
                            },
                            {
                                label: "Title",
                                value: filters.title,
                                setValue: updateTitle,
                                options: TITLE_TAGS,
                                multiSelect: false,
                            },
                            {
                                label: "Location",
                                value: filters.locations,
                                setValue: updateLocation,
                                options: LOCATION_TAGS,
                                multiSelect: false,
                            },
                        ]}
                        setCurrentCompany={setCurrentCompany}
                        setPreviousCompany={setPreviousCompany}
                        companySelectMode="name"
                    />
                </div>

                <div className="flex flex-col flex-1 overflow-hidden">
                    <AdminDashboardTable
                        columns={ADMIN_PEOPLE_MANAGEMENT_TABLE_HEADERS}
                        className="mt-2"
                        TableBody={
                            <div className="flex-1 flex flex-col gap-1 p-5 overflow-y-auto">
                                {loading ? (
                                    <JobBoardLoading page="admin-people-management" />
                                ) : people.length === 0 ? (
                                    <div className="my-8 flex text-neutral-400 flex-col items-center justify-center flex-1">
                                        <SvgSearch />
                                        <p className="body2 mt-2 mb-6">
                                            No professionals found...
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            {people.map((person) => (
                                                <AdminPeopleManagementRow
                                                    key={person.id}
                                                    person={person}
                                                    columns={ADMIN_PEOPLE_MANAGEMENT_TABLE_HEADERS}
                                                    onStatusChange={handleStatusChange}
                                                />
                                            ))}
                                        </div>
                                        {!hideMorePeople && !loading && showLoadMore && (
                                            <div className="fixed bottom-8 left-[calc(50vw+125px)] -translate-x-1/2 z-10">
                                                <Button
                                                    loading={morePeopleLoading}
                                                    variant={"ghost"}
                                                    onClick={getNextPeopleInfo}
                                                    className="bg-white shadow-lg"
                                                >
                                                    Load more professionals
                                                </Button>
                                            </div>
                                        )}
                                        <div ref={bottomRef} className="h-4" />
                                    </>
                                )}
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default connect()(AdminPeopleManagement); 