
import { useSelector } from "react-redux";

export default function useConstants() {
  const constants = useSelector((state) => state.userReducer.constants);
  const majorCategories = constants?.major_categories || {};
  const majorOptions = Object.keys(majorCategories).map(category => ({
    title: category,
    value: category,
  }));
  const verticalOptions = constants?.secondary_tags?.map(tag => ({
    title: tag,
    value: tag,
  }));

  return { constants, majorOptions, verticalOptions, majorCategories };
}
