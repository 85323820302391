import { getQualificationParams } from "./getQualificationsParams";
/**
 * Creates parameters object for job or event creation
 * @param {Object} data - Form data and state values
 * @param {string} type - Either 'job' or 'event'
 * @param {string} tier - User tier
 * @returns {Object} Formatted parameters object
 */
export const createJobEventModalParams = (data, type, tier) => {
    const industry = data.role?.endsWith('Consulting') ? 'Consulting' : 'Finance';
    
    // Common params for both jobs and events
    const params = {
        name: data[type === 'job' ? 'jobTitle' : 'eventTitle'],
        description: data.htmlContent,
        city: !!data.city ? [data.city] : [],
        state: !!data.state ? [data.state] : [],
        industry,
        scope: tier !== "blk" ? "public" : "club",
        qualified_only: data.qualifiedOnly,
        exclusive: true,
        specialties: [industry],
        secondary_specialties: [data.role],
        qualified_only_selection_mode: data.selectionMode,
    };

    // TODO: what is this?
    // clubs: tier !== "blk" ? combinedClubIds : ["baeZVpmndkm8fCNGFIuZ"],

    // Add type-specific params
    if (type === 'job') {
        Object.assign(params, {
            title: data.jobTitle,
            type: data.jobType,
            work_model: data.workEnvironment,
            link: data.url,
            apply_direct: data.directOnRecruit,
            deadline: data.hasDeadline ? data.deadline : "",
            openings: data.openings,
            application_questions: data.applicationQuestions.filter(question => !!question.text),
            job_pdf_path: data.jobPdfPath,
        });

        // Add job PDF ID if path exists
        if (data.jobPdfPath) {
            params.id = data.jobId;
        }
    } else if (type === 'event') {
        Object.assign(params, {
            company_id: data.companyId,
            creator: data.creator,
            location: data.virtual ? "Remote" : "In-Person",
            event_date: data.date,
            start_time: data.startTime,
            end_time: data.endTime,
            address: data.address,
            link: data.meetingLink,
            register_direct: data.registerOnRecruit,
            rsvp_date: data.hasRsvpDate ? data.rsvpDate : "",
        });
    }


    Object.assign(params, getQualificationParams(data));

    return params;
};

