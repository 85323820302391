import React, {Component, createRef} from "react";
import {Button} from "../atoms/Button";
import SvgPlayArrow from "../icons/SvgPlayArrow";
import SvgPause from "../icons/SvgPause";
import {DateTime} from 'luxon';
import SvgSpeed from "../icons/SvgSpeed";
import SvgThumbDown from "../icons/SvgThumbDown";
import SvgThumbUp from "../icons/SvgThumbUp";
import SvgClock from "../icons/SvgClock";
import TooltipComponent from "../atoms/TooltipComponent";
import {roundTo} from "../../utils/strings";
import SvgCelebration from "../icons/SvgCelebration";
import { getQualitativeRating } from "../../utils/stats";


class QuestionResponseListItem extends Component {
    state = {
        isCollapsed: true,
        isVideoPlaying: false,
        firstPlay: true
    }
    videoRef = createRef();

    toggleCollapse = () => {
        this.setState({isCollapsed: !this.state.isCollapsed})
    }

    togglePlay = () => {
        const video = this.videoRef.current;
        if (video.paused) {
            video.play();
            this.setState({isVideoPlaying: true, firstPlay: true})
        } else {
            video.pause();
            this.setState({isVideoPlaying: false})
        }
    }

    getWordsPerMin = () => {
        const item = this.props.item || {};
        if (!item.wpm) return {
            title: "--",
            color: 'text-slate-500',
        }

        if (item.wpm > 130 && item.wpm < 160) return {
            title: "Perfect",
            color: 'text-green-500',
        }; else if (item.wpm > 160) return {
            title: "Too fast",
            color: 'text-orange-500',
        }; else return {
            title: "Too slow",
            color: 'text-blue-500',
        }
    }

    renderScoreIcon = (score) => {
        const rating = getQualitativeRating(score);
        if (!rating) return null;
        
        switch (rating.icon) {
            case "thumbs-down":
                return <SvgThumbDown className="w-6 h-6" />;
            case "thumbs-up":
                return <SvgThumbUp className="w-6 h-6" />;
            case "celebration":
                return <SvgCelebration className="w-6 h-6" />;
            default:
                return null;
        }
    }

    render() {
        const item = this.props.item;
        const createdAt = DateTime.fromISO(item.created_at);
        const rating = getQualitativeRating(item.score);
        return (
            <div
                key={item.id}
                className='border border-slate-200 overflow-hidden rounded-xl shadow-xl'>
                <div className='bg-slate-200 group rounded-lg rounded-b-none w-full h-[400px] relative'>
                    <video ref={this.videoRef} className="w-full h-full object-cover scale-x-[-1]" controls={false}
                           preload="metadata">
                        <source src={item.video_url + '#t=0.5'} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                    {(!this.state.isVideoPlaying && this.state.firstPlay) &&
                        <div
                            onClick={this.togglePlay}
                            className='absolute flex flex-col items-center justify-center left-0 right-0 top-0 bottom-0'>
                            <Button size={'icon'}>
                                <SvgPlayArrow/>
                            </Button>
                        </div>
                    }
                    {this.state.isVideoPlaying &&
                        <div className='absolute left-0 right-0 top-0 bottom-0' onClick={this.togglePlay}/>
                    }
                    {(this.state.isVideoPlaying || !this.state.firstPlay) &&
                        <div className='absolute opacity-0 group-hover:opacity-100 left-2 right-2 bottom-2'>
                            <Button onClick={this.togglePlay} size={'icon'}>
                                {this.state.isVideoPlaying ?
                                    <SvgPause/>
                                    :
                                    <SvgPlayArrow/>
                                }
                            </Button>
                        </div>
                    }
                </div>
                <div
                    onClick={this.toggleCollapse}
                    className='flex flex-row items-center justify-between group rounded-lg p-5 pb-0 cursor-pointer'>
                    <div>
                        <div className="flex items-center gap-2">
                            {this.renderScoreIcon(item.score)}
                            <p className={`text-4xl font-semibold ${rating?.color || 'text-slate-500'}`}>
                                {rating?.text || "--"}
                            </p>
                        </div>
                        <p className={'md:text-xs lg:text-xs text-slate-500 -mt-2'}>
                            {item.status} Submission
                        </p>
                    </div>
                    <div className='flex flex-row items-center text-slate-700 gap-8'>
                        <div>
                            <div className={`flex flex-row items-center gap-1`}>
                                <SvgThumbUp/>
                                <p className='text-xl font-bold'>
                                    {item.strengths.length || "--"}
                                </p>
                            </div>
                            <p className='md:text-xs lg:text-xs text-slate-500'>
                                Strengths
                            </p>
                        </div>
                        <div>
                            <div className={`flex flex-row items-center gap-1`}>
                                <SvgThumbDown/>
                                <p className='text-xl font-bold'>
                                    {item.weaknesses.length || "--"}
                                </p>
                            </div>
                            <p className='md:text-xs lg:text-xs text-slate-500'>
                                Weaknesses
                            </p>
                        </div>
                        <div>
                            <TooltipComponent
                                ToggleComponent={
                                    <div className={`flex flex-row items-center gap-1 `}>
                                        <SvgSpeed/>
                                        <p className={`text-xl font-bold`}>
                                            {!!item.wpm ? roundTo(item.wpm, 0) : 'N/A'}
                                        </p>
                                    </div>
                                }
                                hover
                                placement={'bottom'}
                                label={!!item.wpm ? roundTo(item.wpm, 2) + ' wpm' : 'N/A'}
                            />
                            <p className='md:text-xs lg:text-xs text-slate-500'>
                                Words/min
                            </p>
                        </div>
                        <div>
                            <div className={`flex flex-row items-center gap-1`}>
                                <SvgClock/>
                                <p className='text-xl font-bold'>
                                    {item.status !== "Pending" ? item.video_length.toFixed(0) + "s" : "--"}
                                </p>
                            </div>
                            <p className='md:text-xs lg:text-xs text-slate-500'>
                                Tot. Length
                            </p>
                        </div>
                        {/*<SvgChevronDown*/}
                        {/*    className={`scale-150 group-hover:text-primary ${!this.state.isCollapsed ? 'rotate-[180deg]' : ''}`}/>*/}
                    </div>
                </div>
                {/*<CollapseComponent isOpen={!this.state.isCollapsed}>*/}
                <div className='p-5 flex flex-col gap-3'>
                    {item.status === "Graded" &&
                        <div>
                            <div className='flex flex-row gap-3 mb-5'>
                                <div className='flex flex-col gap-2'>
                                    <p className='text-lg font-bold'>
                                        Overall Feedback
                                    </p>
                                    <p className='text-sm' key={item.feedback}>
                                        {item.feedback}
                                    </p>
                                </div>
                            </div>
                            <div className='flex flex-row gap-12 '>
                                <div className='flex flex-col gap-2 flex-1'>
                                    <p className='text-lg font-bold'>
                                        Strengths
                                    </p>
                                    <ul className='text-sm'>
                                        {item.strengths.length === 0 &&
                                            <p className='opacity-50'>
                                                We couldn't find any strengths, take a look at some of the feedback
                                                and try again! Practice makes perfect.
                                            </p>
                                        }
                                        {item.strengths.map(item =>
                                            <p className='ml-5' key={item}>
                                                - {item}
                                            </p>
                                        )}
                                    </ul>
                                </div>
                                <div className='flex flex-col gap-2 flex-1'>
                                    <p className='text-lg font-bold'>
                                        Weaknesses
                                    </p>
                                    <ul className='text-sm'>
                                        {item.weaknesses.length === 0 &&
                                            <p className='opacity-50'>
                                                Wow! We couldn't find any flaws. Well done!
                                            </p>
                                        }
                                        {item.weaknesses.map(item =>
                                            <p className='ml-5' key={item}>
                                                - {item}
                                            </p>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                    <p className={'text-sm text-slate-500'}>
                        Submit {createdAt.toRelative()}
                    </p>
                </div>
                {/*</CollapseComponent>*/}
            </div>
        )
    }
}

export default QuestionResponseListItem
