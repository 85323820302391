import React from "react";
import SvgSearchFeather from "../../../components/icons/SvgSearchFeather";
import SvgList from "../../../components/icons/SvgList";
import JobEventQualificationsListSelector from "./JobEventQualificationsListSelector";
import JobEventQualificationsFilters from "./JobEventQualificationsFilters";
import { useQualifiedOnly } from "../../../contexts/QualifiedOnlyContext";

export default function QualifiedOnlyOptions() {
  const { selectionMode, setSelectionMode } = useQualifiedOnly()
  const selectionModeOptions = [
    {
      id: "lists",
      icon: SvgList,
      title: "Select Lists",
      description:
        "Choose from your Smart lists, Student lists, and Club lists",
    },
    {
      id: "filters",
      icon: SvgSearchFeather,
      title: "Select Filters",
      description:
        "Set specific criteria for education, experience, and demographics",
    },
  ];

  return (
      <div className="flex flex-col gap-4">
       <p className="text-md text-slate-500 font-semibold text-slate-800">
          Please select ONE of the following options
        </p>
      
        <div className="flex gap-4">
          {selectionModeOptions.map((option) => (
            <button
              key={option.id}
              onClick={() => setSelectionMode(option.id)}
              className={`flex-1 !p-2 rounded-lg !border ${
                selectionMode === option.id
                  ? "border-blue-300"
                  : "border-gray-200 hover:border-gray-300"
              }`}
            >
              <div className="flex items-center gap-2">
                <option.icon className="w-5 h-5" />
                <span className="font-medium text-sm">{option.title}</span>
              </div>
              <p className="text-sm text-gray-600 mt-1 text-left px-1">
                {option.description}
              </p>
            </button>
          ))}
        </div>

        {selectionMode === "lists" ? (
          <JobEventQualificationsListSelector />
        ) : (
          <JobEventQualificationsFilters />
        )}
      </div>
  );
}
