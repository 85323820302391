import { useMemo, useState } from "react";
import useConstants from "./useConstants";

const INITIAL_FILTERS = {
  schools: [],
  majors: [],
  classYears: [],
  gpa: null,
  clubs: [],
  currentCompanies: [],
  previousCompanies: [],
  verticals: [],
  gender: null,
  race: [],
  veteran: false,
  lgbtq: false,
  disabled: false,
  authorized: false,
};

export default function useQualifiedOnlyState() {
  const { majorCategories } = useConstants();
  const [selectionMode, setSelectionMode] = useState("lists");
  const [lists, setLists] = useState({
    smart: {
      items: [],
      ids: [],
    },
    club: {
      items: [],
      ids: [],
    },
    user: {
      items: [],
      ids: [],
    },
  });
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [selectedMajorCategories, setSelectedMajorCategories] = useState([]);

  const listNames = useMemo(() => {
    const smartLists = lists.smart.items.map((item) => ({
      id: item.id,
      name: item.name,
      type: "smart",
    }));
    const clubLists = lists.club.items.map((item) => ({
      id: item.id,
      name: item.name,
      type: "club",
    }));
    const userLists = lists.user.items.map((item) => ({
      id: item.id,
      name: item.name,
      type: "user",
    }));

    return [...smartLists, ...clubLists, ...userLists];
  }, [lists]);

  function setSmartLists(lists) {
    setLists((prev) => ({
      ...prev,
      smart: {
        items: lists,
        ids: lists.map((list) => list.id),
      },
    }));
  }

  function setClubLists(lists) {
    setLists((prev) => ({
      ...prev,
      club: {
        items: lists,
        ids: lists.map((list) => list.id),
      },
    }));
  }

  function setUserLists(lists) {
    setLists((prev) => ({
      ...prev,
      user: {
        items: lists,
        ids: lists.map((list) => list.id),
      },
    }));
  }

  function setFilter(filterKey, value) {
    setFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  }

  function handleSmartListSelection(list) {
    const isSelected = lists.smart.ids.includes(list.id);
    if (isSelected) {
      const filteredLists = lists.smart.items.filter(
        (item) => item.id !== list.id
      );
      setSmartLists(filteredLists);
    } else {
      const newLists = [...lists.smart.items, list];
      setSmartLists(newLists);
    }
  }

  function handleClubListSelection(list) {
    const isSelected = lists.club.ids.includes(list.id);
    if (isSelected) {
      const filteredLists = lists.club.items.filter(
        (item) => item.id !== list.id
      );
      setClubLists(filteredLists);
    } else {
      const newLists = [...lists.club.items, list];
      setClubLists(newLists);
    }
  }

  function handleUserListSelection(list) {
    const isSelected = lists.user.ids.includes(list.id);
    if (isSelected) {
      const filteredLists = lists.user.items.filter(
        (item) => item.id !== list.id
      );
      setUserLists(filteredLists);
    } else {
      const newLists = [...lists.user.items, list];
      setUserLists(newLists);
    }
  }


  function handleFilterMajorsSelection(category) {
    const isSelected = selectedMajorCategories.includes(category);
    if (isSelected) {
      const newCategories = selectedMajorCategories.filter(
        (item) => item !== category
      );
      const remainingMajors = filters.majors.filter((major) =>
        newCategories.some((cat) => majorCategories[cat]?.includes(major))
      );

      setSelectedMajorCategories(newCategories);
      setFilter("majors", remainingMajors);
    } else {
      const newMajors = [
        ...new Set([...filters.majors, ...(majorCategories[category] || [])]),
      ];

      setSelectedMajorCategories([...selectedMajorCategories, category]);
      setFilter("majors", newMajors);
    }
  }

  function handleFilterClassYearsSelection(year) {
    const isSelected = filters.classYears.includes(year);
    if (isSelected) {
      setFilter(
        "classYears",
        filters.classYears.filter((item) => item !== year)
      );
    } else {
      setFilter("classYears", [...filters.classYears, year]);
    }
  }




  function handleFilterVerticalsSelection(vertical) {
    const isSelected = filters.verticals.includes(vertical);
    if (isSelected) {
      setFilter(
        "verticals",
        filters.verticals.filter((item) => item !== vertical)
      );
    } else {
      setFilter("verticals", [...filters.verticals, vertical]);
    }
  }

  function initQualifiedOnlyState(jobOrEvent) {
    if (jobOrEvent) {
      if (jobOrEvent.qualified_only_selection_mode) {
        setSelectionMode(jobOrEvent.qualified_only_selection_mode);
      }

      if (jobOrEvent.filters) {
        const parsedFilters = {};
        const filters = jobOrEvent.filters;
        
        if (filters.schools?.length) parsedFilters.schools = filters.schools;
        if (filters.majors?.length) parsedFilters.majors = filters.majors;
        if (filters.class_years?.length) parsedFilters.classYears = filters.class_years;
        if (filters.gpa) parsedFilters.gpa = filters.gpa;
        if (filters.clubs?.length) parsedFilters.clubs = filters.clubs;
        if (filters.current_companies?.length) parsedFilters.currentCompanies = filters.current_companies;
        if (filters.previous_companies?.length) parsedFilters.previousCompanies = filters.previous_companies;
        if (filters.verticals?.length) parsedFilters.verticals = filters.verticals;
        if (filters.gender) parsedFilters.gender = filters.gender;
        if (filters.race?.length) parsedFilters.race = filters.race;
        if (filters.veteran) parsedFilters.veteran = filters.veteran;
        if (filters.lgbtq) parsedFilters.lgbtq = filters.lgbtq;
        if (filters.disabled) parsedFilters.disabled = filters.disabled;
        if (filters.authorized) parsedFilters.authorized = filters.authorized;

        setFilters({ ...INITIAL_FILTERS, ...parsedFilters });

        // Populate selected major categories based on majors
        if (parsedFilters.majors?.length) {
          const categories = Object.entries(majorCategories)
            .filter(([category, majors]) => 
              parsedFilters.majors.some(major => majors.includes(major))
            )
            .map(([category]) => category);
          setSelectedMajorCategories(categories);
        } else {
          setSelectedMajorCategories([]);
        }
      }

      setLists({
        smart: {
          items: jobOrEvent?.smart_lists?.map(({ list }) => list) ?? [],
          ids: jobOrEvent?.smart_list_ids ?? [],
        },
        club: {
          items: jobOrEvent?.club_lists?.map(({ list }) => list) ?? [],
          ids: jobOrEvent?.club_list_ids ?? [],
        },
        user: {
          items: jobOrEvent?.user_lists?.map(({ list }) => list) ?? [],
          ids: jobOrEvent?.user_list_ids ?? [],
        },
      });
    }
  }

  return {
    selectionMode,
    setSelectionMode,
    listNames,
    setSmartLists,
    setClubLists,
    setUserLists,
    handleSmartListSelection,
    handleClubListSelection,
    handleUserListSelection,
    handleFilterMajorsSelection,
    handleFilterClassYearsSelection,
    handleFilterVerticalsSelection,
    filters,
    lists,
    setFilter,
    initQualifiedOnlyState,
    selectedMajorCategories,
    setSelectedMajorCategories,
  };
}
