import React, {Component} from "react";
import {calculateStatistics, renderQuestionCategoryIcon, scoreColor, getQualitativeRating} from "../../utils/stats";
import CollapseComponent from "../atoms/CollapseComponent";
import ResourcesQuestionCard from "./ResourcesQuestionCard";
import ResourcesZeroState from "./ResourcesZeroState";
import {roundTo} from "../../utils/strings";
import SvgChevronDown from "../icons/ChevronDown";
import SvgThumbDown from "../icons/SvgThumbDown";
import SvgThumbUp from "../icons/SvgThumbUp";
import SvgCelebration from "../icons/SvgCelebration";


class ResponsesCategoryGroup extends Component {
    state = {
        isCollapsed: true,
    }

    countCompletedQuestions = () => {
        const {keyedAnswers, questions} = this.props;
        return questions.filter(question => keyedAnswers[question.id] != null).length;
    }

    renderScoreIcon = (score) => {
        const rating = getQualitativeRating(score);
        if (!rating) return null;
        
        switch (rating.icon) {
            case "thumbs-down":
                return <SvgThumbDown className="w-4 h-4" />;
            case "thumbs-up":
                return <SvgThumbUp className="w-4 h-4" />;
            case "celebration":
                return <SvgCelebration className="w-4 h-4" />;
            default:
                return null;
        }
    }

    render() {
        const category = this.props.category
        const Icon = renderQuestionCategoryIcon(category);
        const answers = this.props.answers || [];
        const completedCount = this.countCompletedQuestions();
        const completedPercent = (completedCount / this.props.questions?.length) * 100
        const stats = calculateStatistics(answers);
        const topScoreRating = getQualitativeRating(stats.topScore);
        const avgScoreRating = getQualitativeRating(stats.averageScore);

        return (
            <div className={`border overflow-hidden border-slate-200 rounded-xl transition-all duration-150 ${this.props.selectedTopic === category && 'my-3 mx-2 shadow-slate-200 shadow-xl outline outline-primary outline-offset-2'}`}>
                <div className='p-5 flex flex-row hover:bg-slate-50 cursor-pointer items-center gap-3 justify-between'
                     onClick={() => this.props.selectTopic(category)}>
                    <div className='flex flex-row gap-3'>
                        <Icon className={`w-10 h-10 mt-2 ${this.props.selectedTopic === category ? 'text-primary' : (this.props.selectedTopic.length === 0  ? 'text-slate-800' : 'text-slate-500')}`}/>
                        <div>
                            <div className='flex flex-row items-center'>
                                <p className='text-slate-900 text-lg font-bold truncate'>
                                    {category}
                                </p>
                                <p className='text-sm text-slate-500 font-semibold ml-2'>
                                    ({completedCount}/{this.props.questions.length} complete)
                                </p>
                            </div>
                            <div className='flex flex-row items-center gap-3'>
                                <p className='text-sm font-semibold text-primary'>
                                    {roundTo(completedPercent, 0)}%
                                </p>
                                <div className='bg-primary/10 mt-1 h-min rounded-full w-60 overflow-hidden'>
                                    <div className={'h-2 bg-primary'} style={{width: completedPercent + '%'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-8'>
                        <div className='text-center w-20'>
                            <p className={`m-0 text-lg font-bold ${answers?.length > 0 ? 'text-primary' : 'text-slate-500'}`}>
                                {answers.length}
                            </p>
                            <p className='text-xs font-semibold text-slate-400'>
                                Submissions
                            </p>
                        </div>
                        <div className='text-center w-20'>
                            <div className="flex justify-center">
                                {this.renderScoreIcon(stats.topScore)}
                            </div>
                            <p className={`text-sm font-bold mt-1 ${topScoreRating?.color}`}>
                                {topScoreRating?.text || "--"}
                            </p>
                            <p className='text-xs font-semibold text-slate-400'>
                                Best
                            </p>
                        </div>
                        <div className='text-center w-20'>
                            <div className="flex justify-center">
                                {this.renderScoreIcon(stats.averageScore)}
                            </div>
                            <p className={`text-sm font-bold mt-1 ${avgScoreRating?.color}`}>
                                {avgScoreRating?.text || "--"}
                            </p>
                            <p className='text-xs font-semibold text-slate-400'>
                                Average
                            </p>
                        </div>
                        <SvgChevronDown className={`scale-[2] duration-150 ${this.props.selectedTopic === category ? 'rotate-180 text-primary' : 'rotate-0 text-slate-400'}`}/>
                    </div>
                </div>
                <CollapseComponent isOpen={this.props.selectedTopic === category}>
                    <div className='w-full border-t border-slate-200 py-2 px-3'>
                        {this.props.questions.map(question =>
                            <ResourcesQuestionCard
                                item={question}
                                score={this.props.keyedAnswers[question.id]}
                            />
                        )}
                        <ResourcesZeroState changeTab={this.props.changeTab}/>
                    </div>
                </CollapseComponent>
            </div>
        )
    }
}

export default ResponsesCategoryGroup
