import React, {Component} from "react";
import SvgBarChart from "../icons/SvgBarChart";
import SvgCheckCircle from "../icons/SvgCheckCircle";
import SvgHourglass from "../icons/SvgHourglass";
import SvgResume from "../icons/SvgResume";
import {roundTo} from "../../utils/strings";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    };
}

class InterviewSetListItem extends Component {

    render() {
        const set = this.props.set;
        const answerIds = this.props.answerIds
        const commonIds = set.question_ids.filter(id => answerIds.includes(id))
        const finished = commonIds.length === set.question_ids.length
        const started = commonIds.length > 0;
        return (
            <div className={`
                 border p-5 px-3 rounded-xl w-full
                 ${set.name === this.props.selectedSet?.name ? 'border-primary bg-primary/5' : 'border-slate-200 hover:border-primary/50'}
                 flex flex-col cursor-pointer hover:bg-slate-50 ${this.props.isGridView ? '' : 'gap-3'}`}
                 onClick={() => this.props.selectSetToPreview(set)}
            >
                <div className='flex flex-col'>
                    {finished ?
                        <div className='flex flex-row items-center gap-1'>
                            <SvgCheckCircle className={'text-primary w-4 h-4'}/>
                            <p className='text-xs font-semibold text-primary'>
                                FINISHED
                            </p>
                        </div>
                        :
                        started ?
                            <div className='flex flex-row items-center gap-1'>
                                <SvgResume className={'text-orange-500 w-4 h-4'}/>
                                <p className='text-xs font-semibold text-orange-500'>
                                    STARTED
                                </p>
                            </div>
                            :
                            <div className='flex flex-row items-center gap-1'>
                                <SvgHourglass className={'text-slate-500 w-4 h-4'}/>
                                <p className='text-xs font-semibold text-slate-500'>
                                    NOT STARTED
                                </p>
                            </div>
                    }
                    <p className='text-xl font-semibold line-clamp-2 h-14'>
                        {set.name}
                    </p>
                </div>
                <div className={`flex flex-row   ${this.props.isGridView ? 'flex-col' : 'items-center gap-8'}`}>
                    <div>
                        <p className='text-sm text-slate-500'>
                            Questions
                        </p>
                        <p className='font-semibold'>
                            {set.questions.length}
                            {/*<span className='text-slate-500 text-sm'>({commonIds.length}/{set.question_ids.length})</span>*/}
                        </p>
                    </div>
                
                   
                    <div>
                        <p className='text-sm text-slate-500'>
                            Est Time
                        </p>
                        <p className='font-semibold'>
                            {set.estimated_time}
                        </p>
                    </div>
                    <div>
                        <p className='text-sm text-slate-500'>
                            Difficulty
                        </p>
                        <div className='flex flex-row items-center gap-2'>
                            <SvgBarChart difficulty={set.difficulty} className={'w-4 h-4'}/>
                            <p className='font-semibold'>
                                {set.difficulty}
                            </p>
                        </div>
                    </div>
              
                </div>
                {!this.props.isGridView &&
                <div className='flex flex-row items-center gap-3 -mb-1'>
                    <p className='text-sm font-semibold text-primary'>
                        {roundTo((commonIds.length / set.question_ids.length) * 100, 0)}%
                    </p>
                    <div className='bg-primary/10 w-full mt-0.5 overflow-hidden h-2 rounded-full'>
                        <div className={`bg-primary h-2`}
                             style={{width: `${(commonIds.length / set.question_ids.length) * 100}%`}}/>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default InterviewSetListItem;
