import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getPracticeSets } from "../../api/student/practice_sets";
import ResponsesCategoryGroup from "./ResponsesCategoryGroup";
import ResourcesZeroState from "./ResourcesZeroState";
import {question_categories_array} from "../../utils/constants";
import { isFeatureEnabled } from "../../config/featureFlags";

function ResourcesQuestionBankNew({ answers, selectedTopic: propSelectedTopic, changeTab }) {
    const authUser = useSelector(state => state.userReducer.authUser);
    const user = useSelector(state => state.userReducer.user);

    const [state, setState] = useState({
        grid: true,
        submissions: [],
        category: "",
        loading: true,
        activeTab: 'All Submissions',
        categorizedAnswers: [],
        keyedAnswers: {},
        categorizedQuestions: {},
        categoryKeyedAnswers: [],
        selectedTopic: propSelectedTopic || 'Private Equity / Buyside Recruiting'
    });

    const categorizeAnswers = () => {
        const categories = [...new Set(answers.map(answer => answer.question.category))];
        
        // Filter categories if learn optimization is disabled
        const filteredCategories = isFeatureEnabled('LEARN_OPTIMIZATION') 
            ? categories 
            : categories.filter(cat => question_categories_array.includes(cat));

        let categorizedAnswers = filteredCategories.map(category => ({
            category,
            answers: answers.filter(answer => answer.question.category === category)
        }));

        categorizedAnswers.sort((a, b) => b.answers?.length - a.answers?.length);

        const categoryKeyedAnswers = filteredCategories.reduce((acc, category) => {
            acc[category] = answers.filter(answer => answer.question.category === category);
            return acc;
        }, {});

        const keyedAnswers = {};
        for (const answer of answers) {
            if (!(answer.question_id in keyedAnswers)) keyedAnswers[answer.question_id] = 0;
            if (answer.score > keyedAnswers[answer.question_id]) keyedAnswers[answer.question_id] = answer.score;
        }

        setState(prev => ({
            ...prev,
            keyedAnswers,
            categorizedAnswers,
            categoryKeyedAnswers
        }));
    };

    useEffect(() => {
        const init = async () => {
            categorizeAnswers();
            const res = await getPracticeSets(authUser);
            
            // Transform practice sets into categorized format
            const categorizedSets = res.practice_sets.reduce((acc, set) => {
                // Filter out categories if learn optimization is disabled
                if (!isFeatureEnabled('LEARN_OPTIMIZATION') && 
                    !question_categories_array.includes(set.name)) {
                    return acc;
                }
                
                // Use the set name as the category
                if (!acc[set.name]) {
                    acc[set.name] = [];
                }
                // Add all questions from this set to its category
                acc[set.name].push(...set.questions);
                return acc;
            }, {});

            setState(prev => ({
                ...prev,
                categorizedQuestions: categorizedSets,
                selectedTopic: Object.keys(categorizedSets)[0]
            }));
        };
        init();
    }, []);

    useEffect(() => {
        categorizeAnswers();
    }, [answers]);

    const selectTopic = (selectedTopic) => {
        setState(prev => ({
            ...prev,
            selectedTopic: selectedTopic === state.selectedTopic ? '' : selectedTopic
        }));
    };

    const handleChangeTab = (tab) => {
        setState(prev => ({
            ...prev,
            activeTab: tab
        }));
    };
    
    return (
        <div className='flex flex-col flex-1 overflow-y-scroll w-full'>
            <div className='flex flex-col gap-1 mt-5 pb-5'>
                {Object.keys(state.categorizedQuestions).map(category =>
                    <ResponsesCategoryGroup
                        key={category}
                        category={category}
                        keyedAnswers={state.keyedAnswers}
                        answers={state.categoryKeyedAnswers[category]}
                        questions={state.categorizedQuestions[category]}
                        changeTab={changeTab}
                        selectTopic={selectTopic}
                        selectedTopic={state.selectedTopic}
                    />
                )}
            </div>
        </div>
    );
}

export default ResourcesQuestionBankNew;
