import React from 'react';
import { Button } from "../atoms/Button";
import SvgCheck from "../icons/SvgCheck";
import SvgClose from "../icons/SvgClose";

const InterviewSetNavigation = ({
    interviewSet,
    currentQuestionId,
    onNavigate,
    onNext,
    onPrevious,
    answerIds = [],
    questionName,
}) => {
    if (!interviewSet) return null;

    return (
        <div className='flex flex-row items-center justify-between border border-slate-200 rounded-xl p-5 mt-5'>
            <div>
                <p className='text-sm font-semibold text-slate-500 -mb-1'>
                    Interview Set
                </p>
                <p className={'text-xl font-semibold'}>
                    {interviewSet.name}
                </p>
            </div>
            <div className='flex flex-row gap-3 items-center'>
                <Button size={'sm'} variant={'ghost'} onClick={onPrevious}>
                    Previous
                </Button>
                {interviewSet.questions.map(item => (
                    <div
                        key={item.id}
                        onClick={() => onNavigate(item)}
                        className={`
                            w-6 h-6 flex flex-col items-center justify-center rounded-full
                            ${item.id === currentQuestionId 
                                ? 'outline outline-offset-2 outline-primary/50 bg-primary' 
                                : (answerIds.includes(item.id) ? 'bg-primary' : 'bg-slate-200 hover:bg-slate-300 cursor-pointer')}
                        `}
                    >
                        {answerIds.includes(item.id) ?
                            <SvgCheck className={'w-5 h-5 text-white'}/>
                            :
                            (questionName && item.name !== questionName) ?
                                <SvgClose className={'w-5 h-5 text-white'}/>
                                :
                                <></>
                        }
                    </div>
                ))}
                <Button size={'sm'} variant={'ghost'} onClick={onNext}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export default InterviewSetNavigation; 