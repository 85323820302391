import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Tooltip from "../components/atoms/modals/Tooltip";
import SchoolLogo from "../components-recruiter/atoms/SchoolLogo";
import ProfilePicture from "../components-recruiter/atoms/ProfilePicture";
import SvgLinkedin from "../components/icons/SvgLinkedIn";
import classNames from "classnames";
import * as Collapsible from '@radix-ui/react-collapsible';
import { RowSpacingIcon } from "@radix-ui/react-icons";
import { numToMonth } from "../utils/constants";
import { ArchiveBoxArrowDownIcon, ArchiveBoxXMarkIcon } from '@heroicons/react/24/solid'
import AlertModal from "../components/atoms/modals/AlertModal";
import Checkbox from "../components/atoms/Checkbox";
import { toast } from "react-toastify";
import { verifyUser, unverifyUser, reactivateUser, deactivateUser } from "../api/admin/users";
import { useSelector } from "react-redux";

function AdminPeopleManagementRow({ person = {}, columns, onStatusChange }) {
    const [isVerified, setIsVerified] = useState(person.manually_verified);
    const authUser = useSelector((state) => state.userReducer.authUser);

    useEffect(() => {
        setIsVerified(person.manually_verified);
    }, [person.manually_verified]);

    const handleDeactivateUser = async () => {
        try {
            await toast.promise(deactivateUser(authUser, person.id), {
                pending: "Deactivating professional...",
                success: "Professional deactivated successfully",
                error: "Failed to deactivate professional"
            });
            onStatusChange?.(person.id, true);
        } catch (error) {
            console.error("Error deactivating user:", error);
        }
    }

    const handleReactivateUser = async () => {
        try {
            await toast.promise(reactivateUser(authUser, person.id), {
                pending: "Reactivating professional...",
                success: "Professional reactivated successfully",
                error: "Failed to reactivate professional"
            });
            onStatusChange?.(person.id, false);
        } catch (error) {
            console.error("Error reactivating user:", error);
        }
    }

    const handleVerificationToggle = async () => {
        const newVerificationState = !isVerified;
        setIsVerified(newVerificationState); 

        try {
            const action = !newVerificationState ? unverifyUser : verifyUser;
            await action(authUser, person.id);
        } catch (error) {
            toast.error("Error toggling verification");
            console.error("Error toggling verification:", error);
            setIsVerified(!newVerificationState); // Revert on error
        }
    };

    return (
        <div
            className="grid gap-4 items-center p-4 bg-white hover:bg-slate-200/50 rounded-lg"
            style={{
                gridTemplateColumns: columns.map((col) => col.width).join(" "),
                minWidth: "fit-content",
            }}
        >
            {columns.map((column) => (
                <div
                    key={column.key}
                    className="overflow-hidden text-ellipsis text-sm text-slate-500"
                >
                    {renderColumnContent(column, person, handleDeactivateUser, handleReactivateUser, handleVerificationToggle, isVerified)}
                </div>
            ))}
        </div>
    );
}

const renderColumnContent = (column, person, handleDeactivateUser, handleReactivateUser, handleVerificationToggle, isVerified) => {
    switch (column.key) {
        case "verification":
            return (
                <div className={classNames(
                    "flex flex-row items-center",
                    column.isCentered && "justify-center"
                )}>
                    <span className="cursor-pointer text-xl">
                        <Checkbox checked={isVerified} onClick={handleVerificationToggle} />
                    </span>
                </div>
            );
        case "identity":
            return (
                <div className={classNames(
                    "flex flex-row items-center gap-2",
                    column.isCentered && "justify-center"
                )}>
                    <ProfilePicture
                        image={person.profile_pic_url}
                        userID={person.id}
                        size={40}
                        className="border border-slate-200"
                    />
                    <div className="flex flex-col gap-0.5">
                        <p className="text-slate-800 font-semibold">
                            {person.first_name} {person.last_name}
                        </p>
                        <p className="text-xs">{person.email}</p>
                        {person.linkedin && (
                            <a
                                href={person.linkedin}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:text-blue-700 flex items-center gap-1 text-xs"
                            >
                                <SvgLinkedin className="w-4 h-4" />
                                LinkedIn
                            </a>
                        )}
                    </div>
                </div>
            );
        case "education":
            return (
                <div className="flex flex-col text-xs">
                    <p className="font-semibold">{person.school}</p>
                    {person.undergrad && (
                        <>
                            <p>{person.undergrad.field_of_study}</p>
                            <p>{person.undergrad.degree_name}</p>
                            {person.undergrad.ends_at && (
                                <p className="text-slate-400">
                                    Class of {person.undergrad.ends_at.year}
                                </p>
                            )}
                        </>
                    )}
                </div>
            );
        case "current_role":
            const current = person.current_company || {};
            return (
                <div className="flex flex-col text-xs">
                    <p className="font-semibold">{current.company || person.company_name}</p>
                    <p>{current.title || person.title}</p>
                    {current.starts_at && (
                        <p className="text-slate-400">
                            Since {numToMonth[current.starts_at.month]} {current.starts_at.year}
                        </p>
                    )}
                </div>
            );
        case "location":
            return (
                <div className="flex flex-col text-xs">
                    <p>{person.city || "N/A"}</p>
                    <p className="text-slate-400">{person.country || "N/A"}</p>
                </div>
            );
        case "previous_roles":
            return (
                <Collapsible.Root>
                    <div className="flex flex-col gap-2">
                        {(person.previous_companies || "").split(",").slice(0, 2).map((company, index) => (
                            <div key={index} className="flex flex-col text-xs">
                                <p className="font-semibold">{company}</p>
                                <p>{(person.previous_titles || "").split(",")[index]}</p>
                            </div>
                        ))}
                    </div>

                    {(person.previous_companies || "").split(",").length > 2 && (
                        <>
                            <Collapsible.Content className="flex flex-col gap-2 mt-2">
                                {(person.previous_companies || "").split(",").slice(2).map((company, index) => (
                                    <div key={index + 2} className="flex flex-col text-xs">
                                        <p className="font-semibold">{company}</p>
                                        <p>{(person.previous_titles || "").split(",")[index + 2]}</p>
                                    </div>
                                ))}
                            </Collapsible.Content>

                            <Collapsible.Trigger asChild>
                                <button className="flex items-center justify-center gap-1 mt-2 text-primary hover:text-primary-dark text-xs">
                                    <RowSpacingIcon className="w-3 h-3" />
                                    <span>Toggle More History</span>
                                </button>
                            </Collapsible.Trigger>
                        </>
                    )}
                </Collapsible.Root>
            );
        case "actions":
            return (
                <div className={classNames(
                    "flex flex-row items-center gap-3",
                    column.isCentered && "justify-center"
                )}>
                    {person.deactivated ? (
                        <AlertModal
                            title="Reactivate Professional"
                            description="Are you sure you want to reactivate this professional?"
                            onConfirm={handleReactivateUser}
                        > 
                            <ArchiveBoxArrowDownIcon className="w-6 h-6 text-slate-400 hover:text-green-500 cursor-pointer" />
                        </AlertModal>
                    ) : (
                        <AlertModal
                            title="Archive Professional"
                            description="Are you sure you want to archive this professional?"
                            onConfirm={handleDeactivateUser}
                        >
                           <ArchiveBoxXMarkIcon className="w-6 h-6 text-slate-400 hover:text-red-400 cursor-pointer" />
                        </AlertModal>
                    )}
                </div>
            );
        default:
            return person[column.key] || "-";
    }
};

export default connect()(AdminPeopleManagementRow); 