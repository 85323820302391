export default function getEnv() {
    const env =
        process.env.REACT_APP_PROD_ENV === "true"
            ? "production"
            : process.env.REACT_APP_DEV_ENV === "true"
            ? "development"
            : process.env.REACT_APP_PREVIEW_ENV === "true"
            ? "preview"
            : "local";
    return env;
}
