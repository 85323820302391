import axios from '../axios';

export const getPracticeSets = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/practice-sets", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

