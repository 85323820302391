import React, { useState, useEffect } from "react";
import ModalComponent from "../atoms/modals/ModalComponent";
import { editUser, getUser } from "../../api/student/users";
import useUser from "../../hooks/useUser";
import { isFeatureEnabled } from "../../config/featureFlags";

export default function OnboardingLearnModal() {
    const { authUser, user, setUser } = useUser();
    const [showOnboardingModal, setShowOnboardingModal] = useState(false);
    const isLearnEnabled = isFeatureEnabled('LEARN_OPTIMIZATION')
   
    useEffect(() => {

        if (user && !user.seen_learn_onboarding_tutorial && isLearnEnabled) {
            setShowOnboardingModal(true);
        }
    }, [user]);

    const handleCloseOnboarding = async () => {
        await editUser(authUser, {
            seen_learn_onboarding_tutorial: true,
        });
        const { user: userUpdated } = await getUser(authUser);
        setUser(userUpdated);
        setShowOnboardingModal(false);
    };
    return (
        <ModalComponent
            isOpen={showOnboardingModal}
            toggle={handleCloseOnboarding}
            header="Check out the Learn Section!"
            size="xl"
            backgroundColor="white"
        >
            <div className="aspect-video">
                <iframe
                    title="Learn Section Tutorial"
                    // TODO: Change to the actual video
                    src="https://www.loom.com/embed/0281766fa2d04bb788eaf19e65135184?autoplay=1&hideEmbedTopBar=true"
                    allowFullScreen
                    style={{ width: "100%", height: "100%" }}
                />
            </div>
        </ModalComponent>
    );
}
