import React from "react";
import MultiSearchSchools from "../../../components/search/MultiSearchSchools";
import MultiSearchCompanies from "../../../components/search/MultiSearchCompanies";
import MultiSearchClubs from "../../../components/search/MultiSearchClubs";
import MultiSearchRace from "../../../components/search/MultiSearchRace";
import MultiSelectComponent from "../../../components/atoms/MultiSelectComponent";
import SelectComponent from "../../../components/atoms/SelectComponent";
import SvgCheck from "../../../components/icons/SvgCheck";
import { useQualifiedOnly } from "../../../contexts/QualifiedOnlyContext";
import useConstants from "../../../hooks/useConstants";

export default function JobEventQualificationsFilters() {
    const { majorOptions, verticalOptions } = useConstants();
    const {
        filters,
        setFilter,
        handleFilterMajorsSelection,
        handleFilterClassYearsSelection,
        handleFilterVerticalsSelection,
        selectedMajorCategories,
        setSelectedMajorCategories,
    } = useQualifiedOnly();

    return (
        <div className="flex flex-col gap-6 p-4">
            {/* Education Section */}
            <div>
                <h3 className="text-sm font-medium text-gray-700 mb-3">Education</h3>
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <MultiSearchSchools
                            selectedSchools={filters.schools}
                            setSelectedSchools={(schools) => setFilter('schools', schools)}
                            placeholder="Search schools..."
                        />
                    </div>
                    <MultiSelectComponent
                        label="Major Categories"
                        light
                        value={selectedMajorCategories}
                        setValue={(category) => handleFilterMajorsSelection(category)}
                        clearFilter={() => {
                            setFilter('majors', [])
                            setSelectedMajorCategories([])
                        }}
                        options={majorOptions}
                        multiSelect={true}
                        hasCollapse={true}
                    />
                    <MultiSelectComponent
                        label="Class Year"
                        light
                        value={filters.classYears}
                        setValue={(year) => handleFilterClassYearsSelection(year)}
                        clearFilter={() => setFilter('classYears', [])}
                        options={[
                            {title: "2025"},
                            {title: "2026"},
                            {title: "2027"},
                            {title: "2028"}
                        ]}
                        multiSelect={true}
                        hasCollapse={true}
                    />
                    <SelectComponent
                        label="Min GPA"
                        light
                        value={filters.gpa}
                        setValue={(gpa) => setFilter('gpa', gpa)}
                        clearFilter={() => setFilter('gpa', null)}
                        options={[
                            {title: "3.9"},
                            {title: "3.8"},
                            {title: "3.7"},
                            {title: "3.6"},
                            {title: "3.5"},
                            {title: "3.4"},
                            {title: "3.3"},
                            {title: "3.2"},
                            {title: "3.1"},
                            {title: "3.0"}
                        ]}
                        hasCollapse={true}
                    />
                </div>
            </div>

            {/* Experience Section */}
            <div>
                <h3 className="text-sm font-medium text-gray-700 mb-3">Experience</h3>
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <MultiSearchClubs
                            colleges={filters.schools}
                            selectedClubs={filters.clubs}
                            setSelectedClubs={(clubs) => setFilter('clubs', clubs)}
                            placeholder="Search clubs..."
                        />
                    </div>
                    <div className="col-span-2">
                        <MultiSearchCompanies
                            selectedCompanies={filters.currentCompanies}
                            setSelectedCompanies={(companies) => setFilter('currentCompanies', companies)}
                            placeholder="Current companies"
                        />
                    </div>
                    <div className="col-span-2">
                        <MultiSearchCompanies
                            selectedCompanies={filters.previousCompanies}
                            setSelectedCompanies={(companies) => setFilter('previousCompanies', companies)}
                            placeholder="Previous companies"
                        />
                    </div>
                    {verticalOptions && (
                        <div className="col-span-2">
                            <MultiSelectComponent
                                label="Vertical"
                                light
                                value={filters.verticals}
                                setValue={(vertical) => handleFilterVerticalsSelection(vertical)}
                                clearFilter={() => setFilter('verticals', [])}
                                options={verticalOptions}
                                multiSelect={true}
                                hasCollapse={true}
                            />
                        </div>
                    )}
                </div>
            </div>

            {/* Demographics Section */}
            <div>
                <h3 className="text-sm font-medium text-gray-700 mb-3">Demographics</h3>
                <div className="grid grid-cols-2 gap-4">
                    <SelectComponent
                        label="Gender"
                        light
                        value={filters.gender}
                        setValue={(gender) => setFilter('gender', gender)}
                        clearFilter={() => setFilter('gender', null)}
                        options={[
                            {title: "Male"},
                            {title: "Female"},
                            {title: "Prefer Not to Specify"}
                        ]}
                        hasCollapse={true}
                    />
                    <div>
                        <MultiSearchRace
                            selectedValues={filters.race}
                            setSelectedValues={(races) => setFilter('race', races)}
                            hasCollapse={true}
                        />
                    </div>
                    <div className="col-span-2">
                        <div className="grid grid-cols-2 gap-3">
                            <button onClick={() => setFilter('veteran', !filters.veteran)}
                                    className="flex items-center gap-2 p-2 rounded border border-gray-200 hover:border-gray-300">
                                <div className={`w-4 h-4 flex items-center justify-center rounded border ${filters.veteran ? 'bg-blue-500 border-blue-500' : 'border-gray-300'}`}>
                                    {filters.veteran && <SvgCheck className="text-white w-3 h-3"/>}
                                </div>
                                <span className="text-sm text-gray-600">Veteran</span>
                            </button>
                            <button onClick={() => setFilter('lgbtq', !filters.lgbtq)}
                                    className="flex items-center gap-2 p-2 rounded border border-gray-200 hover:border-gray-300">
                                <div className={`w-4 h-4 flex items-center justify-center rounded border ${filters.lgbtq ? 'bg-blue-500 border-blue-500' : 'border-gray-300'}`}>
                                    {filters.lgbtq && <SvgCheck className="text-white w-3 h-3"/>}
                                </div>
                                <span className="text-sm text-gray-600">LGBTQ</span>
                            </button>
                            <button onClick={() => setFilter('disabled', !filters.disabled)}
                                    className="flex items-center gap-2 p-2 rounded border border-gray-200 hover:border-gray-300">
                                <div className={`w-4 h-4 flex items-center justify-center rounded border ${filters.disabled ? 'bg-blue-500 border-blue-500' : 'border-gray-300'}`}>
                                    {filters.disabled && <SvgCheck className="text-white w-3 h-3"/>}
                                </div>
                                <span className="text-sm text-gray-600">Disability</span>
                            </button>
                            <button onClick={() => setFilter('authorized', !filters.authorized)}
                                    className="flex items-center gap-2 p-2 rounded border border-gray-200 hover:border-gray-300">
                                <div className={`w-4 h-4 flex items-center justify-center rounded border ${filters.authorized ? 'bg-blue-500 border-blue-500' : 'border-gray-300'}`}>
                                    {filters.authorized && <SvgCheck className="text-white w-3 h-3"/>}
                                </div>
                                <span className="text-sm text-gray-600">Work Authorization</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
