import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {Button} from "../atoms/Button";
import InterviewSetListItem from "./InterviewSetListItem";
import InterviewSetPreview from "./InterviewSetPreview";
import {getInterviewSets} from "../../api/student/interview_sets";
import {question_categories} from "../../utils/dummy";
import {renderQuestionCategoryIcon} from "../../utils/stats";
import SvgCheck from "../icons/SvgCheck";
import CopyReferralButton from "../atoms/CopyReferralButton";
import LottieWrapper from "../atoms/LottieWrapper";
import json from '../../img/lottie/Arrows.json'
import SvgLock from "../icons/SvgLock";
import Spinner from "../Spinner";
import { motion, AnimatePresence } from "framer-motion";
import { isFeatureEnabled } from "../../config/featureFlags";

function ResourcesInterviewSets({ selectedSet: initialSelectedSet, answers = [], changeTab }) {
    const authUser = useSelector(state => state.userReducer.authUser);
    const user = useSelector(state => state.userReducer.user);

    const [selectedSet, setSelectedSet] = useState(initialSelectedSet || null);
    const [interviewSets, setInterviewSets] = useState([]);
    const [showCompleted, setShowCompleted] = useState(false);
    const [setsLoading, setSetsLoading] = useState(false);
    const isOptimizationEnabled = isFeatureEnabled('LEARN_OPTIMIZATION');
    const [isGridView, setIsGridView] = useState(!initialSelectedSet && isOptimizationEnabled);
    const [hasInitiallyRendered, setHasInitiallyRendered] = useState(false);

    const selectSetToPreview = (set) => {
        setSelectedSet(set);
        setIsGridView(false);
    };

    const closePreview = () => {
        setSelectedSet(null);
        setIsGridView(true);
    };

    useEffect(() => {
        getSets();
        setHasInitiallyRendered(true);
    }, []);

    const getSets = async () => {
        setSetsLoading(true);
        try {
            const res = await getInterviewSets(authUser);
            if ((user?.referrals?.length ?? 0) >= 2) {
                setInterviewSets(res.interview_sets);
            } else {
                setInterviewSets(res.interview_sets.slice(0,1));
            }
        } finally {
            setSetsLoading(false);
        }
    };

    const renderCategoryIcon = (category) => {
        const Icon = renderQuestionCategoryIcon(category);
        return (
            <Icon className={'w-5 h-5 mr-1 mt-0.5'}/>
        );
    };

    const toggleShowCompleted = () => {
        setShowCompleted(!showCompleted);
    };

    const answerIds = answers.map(answer => answer.question_id);

    return (
        <div className='flex flex-row flex-1 w-full overflow-hidden gap-3 mt-5'>
            <motion.div 
                className={`flex flex-col overflow-hidden`}
                animate={{ 
                    width: isOptimizationEnabled && isGridView ? "100%" : "30%" 
                }}
                initial={hasInitiallyRendered ? false : { width: isOptimizationEnabled && isGridView ? "100%" : "30%" }}
                transition={{ 
                    duration: 0.3,
                    ease: "easeInOut"
                }}
            >
                <div className='border-b border-slate-200 pb-3 relative'>
                    <div className='flex flex-row flex-wrap items-center justify-between'>
                        <p className='text-lg font-semibold'>
                            Interviews
                        </p>
                        <div onClick={toggleShowCompleted}
                             className={`flex cursor-pointer group ${showCompleted ? 'text-primary' : 'text-slate-500 hover:text-slate-700'} flex-row items-center`}>
                            <p className='text-sm mr-2 font-semibold'>
                                Show completed
                            </p>
                            <div
                                className={`${showCompleted ? 'checkbox-active' : 'checkbox border border-slate-300 group-hover:border-slate-500'}`}>
                                {showCompleted && <SvgCheck className={'text-white'}/>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pt-3 flex-1 overflow-y-scroll'>
                    <motion.div 
                        className={isOptimizationEnabled ? (isGridView ? 'grid grid-cols-3 auto-rows-auto gap-4' : 'flex flex-col gap-4') : 'flex flex-col gap-4'}
                        transition={{ 
                            duration: 0.3,
                            ease: "easeInOut"
                        }}
                    >
                        {setsLoading &&
                            <div className='col-span-3 my-32 flex flex-col items-center'>
                                <Spinner/>
                            </div>
                        }
                        {interviewSets.map(set => {
                            const commonIds = set.question_ids.filter(id => answerIds.includes(id));
                            if (showCompleted && commonIds.length !== set.question_ids.length) {
                                return null;
                            }
                            return (
                                <div key={set.id} className='h-full'>
                                    <InterviewSetListItem
                                        set={set}
                                        selectedSet={selectedSet}
                                        selectSetToPreview={selectSetToPreview}
                                        answerIds={answerIds}
                                        isGridView={isGridView}
                                    />
                                </div>
                            );
                        })}
                    </motion.div>
                </div>
            </motion.div>
            
            <AnimatePresence mode="wait">
                {(!isOptimizationEnabled || !isGridView) && (
                    <motion.div 
                        className='w-full flex flex-col gap-3 border-l border-slate-200 pl-5 overflow-hidden'
                        initial={{ width: 0, opacity: 0 }}
                        animate={{ width: "70%", opacity: 1 }}
                        exit={{ width: 0, opacity: 0 }}
                        transition={{ 
                            duration: 0.3,
                            ease: "easeInOut"
                        }}
                    >
                        {selectedSet ?
                            <InterviewSetPreview 
                                set={selectedSet} 
                                answers={answers}
                                answerIds={answerIds}
                                onClose={closePreview}
                            />
                            :
                            <div className='flex flex-col justify-center py-40'>
                                <div className='w-3/4 flex flex-col gap-2 ml-12'>
                                    <div
                                        className='rotate-90 mr-auto -mb-6 -mt-8 hue-rotate-[18deg] saturate-80 brightness-75'>
                                        <LottieWrapper json={json} width={50} height={100}/>
                                    </div>
                                    <p className='text-2xl font-semibold'>
                                        Select an Interview to get started
                                    </p>
                                    <p className='text-slate-500'>
                                        Browse curated interviews in the menu to the left. These lists are designed to help
                                        sharpen your skills and provide valuable experience.
                                    </p>
                                </div>
                            </div>
                        }
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default ResourcesInterviewSets;
