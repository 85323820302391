import React, { useRef, useState } from "react";
import WrappedTextInput from "../../../components/atoms/WrappedTextInput";
import CollapseComponent from "../../../components/atoms/CollapseComponent";
import TagComponent from "../../../components/atoms/TagComponent";
import {
  searchSmartLists,
  searchClubLists,
  searchUserLists,
} from "../../../utils/recruiter_typesense";
import { Button } from "../../../components/atoms/Button";
import SvgSearchFeather from "../../../components/icons/SvgSearchFeather";
import HorizontalDivider from "../../../components/atoms/HorizontalDivider";
import SvgList from "../../../components/icons/SvgList";
import SvgCheck from "../../../components/icons/SvgCheck";
import * as Portal from "@radix-ui/react-portal";
import { usePopper } from "react-popper";
import { toast } from "react-toastify";
import { useQualifiedOnly } from "../../../contexts/QualifiedOnlyContext";
import useUser from "../../../hooks/useUser";

export default function JobEventQualificationsListSelector() {
  const { user } = useUser();
  const {
    listNames,
    handleSmartListSelection,
    handleClubListSelection,
    handleUserListSelection,
    lists,
  } = useQualifiedOnly();


  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [smartLists, setSmartLists] = useState([]);
  const [clubLists, setClubLists] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top-start",
    modifiers: [
      { name: "offset", options: { offset: [0, 2] } },
      { name: "preventOverflow", options: { padding: 8 } },
      {
        name: "flip",
        options: {
          fallbackPlacements: [],
        },
      },
    ],
  });
  const [search, setSearch] = useState("");
  const resultsEndRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const scrollToBottom = () => {
    if (resultsEndRef.current) {
      resultsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const searchLists = async (text) => {
    const smartListRes = await searchSmartLists(text, 1, user.company_id);
    const clubListRes = await searchClubLists(text, 1, user.company_id);
    const userListRes = await searchUserLists(text, 1, user.company_id);

    setSmartLists(smartListRes);
    setClubLists(clubListRes);
    setUserLists(userListRes);
  };

  const handleSearchChange = (event) => {
    const text = event.target.value;
    setSearch(text);
    scrollToBottom();
    if (text) {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
    searchLists(text);
  };

  const deleteList = (item) => {
    if (item.type === "club") {
      handleClubListSelection(item);
    } else if (item.type === "smart") {
      handleSmartListSelection(item);
    } else if (item.type === "user") {
      handleUserListSelection(item);
    }
  };

  const selectSmartList = (res) => {
    setSearch("");
    if (lists.smart.ids?.length > 0 && !lists.smart.ids.includes(res.id)) {
      toast.warning("Only one Smart List can be selected at a time. Please remove the existing Smart List before selecting a new one.");
      return;
    }
    handleSmartListSelection(res);
  };

  const selectClubList = (res) => {
    setSearch("");
    handleClubListSelection(res);
  };

  const selectUserList = (res) => {
    setSearch("");
    handleUserListSelection(res);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
    setSearch("");
  };

  const renderSelectedIcon = (isSelected, className) => {
    return isSelected ? (
      <div
        className={`w-5 h-5 rounded-full flex flex-col items-center justify-center bg-primary ${
          className || "ml-auto"
        }`}
      >
        <SvgCheck className="w-4 h-4 text-white" />
      </div>
    ) : null;
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="text-xs font-medium text-slate-500">Recipients</div>
      <div className="relative">
        <div className={"relative"}>
          <WrappedTextInput
            ref={setReferenceElement}
            type="text"
            placeholder="Search your smart lists, club lists, and user lists"
            className="pl-44 input-light body2 w-full"
            value={search}
            onChange={handleSearchChange}
            icon={
              <SvgSearchFeather
                width={20}
                height={20}
                className={"absolute text-slate-500"}
                style={{ left: 12, top: 10 }}
              />
            }
          />
          <div className="absolute right-2 top-0 bottom-0 flex flex-col items-center justify-center">
            {isDropdownOpen && (
              <div className="absolute w-36 right-2 top-0 bottom-0 flex flex-col items-end justify-center">
                <Button
                  size={"xs"}
                  variant={"secondary"}
                  onClick={closeDropdown}
                >
                  Close search
                </Button>
              </div>
            )}
          </div>
        </div>
        <Portal.Root>
          <div
            ref={setPopperElement}
            style={{
              ...styles.popper,
              zIndex: 1060,
              width: referenceElement?.offsetWidth
                ? `${referenceElement.offsetWidth}px`
                : "auto",
            }}
            {...attributes.popper}
          >
            <CollapseComponent
              isOpen={isDropdownOpen}
              className="bg-white border shadow-xl border-neutral-200 rounded-lg p-2 max-h-[45vh] overflow-y-scroll"
            >
              <div>
                <p className="text-sm mb-1 mt-2 font-medium">Smart Lists</p>
                {smartLists.map((res) => {
                  const isSelected = lists.smart.ids?.includes(res.id);
                  return (
                    <div
                      key={res.id}
                      className={`flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2`}
                      onClick={() => selectSmartList(res)}
                    >
                      <div className="w-8 h-8 flex flex-col items-center justify-center rounded-lg object-cover border border-slate-200">
                        <SvgList className={"w-5 h-5 text-slate-700"} />
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm text-slate-800 line-clamp-2">
                          {res.name}
                        </p>
                        <p className="text-xs text-slate-500 line-clamp-2">
                          Smart List - Created by {res.creator_name}
                        </p>
                      </div>
                      {renderSelectedIcon(isSelected)}
                    </div>
                  );
                })}
                {smartLists.length === 0 && (
                  <div className="flex flex-col items-center mt-1">
                    <p className="text-sm text-slate-800 line-clamp-2">
                      No smart lists for this search
                    </p>
                  </div>
                )}
              </div>
              <HorizontalDivider className="bg-slate-200 my-3" />
              <div>
                <p className="text-sm mb-1 mt-2 font-medium">Club Lists</p>
                {clubLists.map((res) => {
                  const isSelected = lists.club.ids.includes(res.id);
                  return (
                    <div
                      key={res.id}
                      className={`flex flex-row items-center gap-3 py-2 cursor-pointer ${
                        !isSelected && "hover:bg-slate-100"
                      } rounded-xl px-2`}
                      onClick={() => selectClubList(res)}
                    >
                      <div className="w-8 h-8 flex flex-col items-center justify-center rounded-lg object-cover border border-slate-200">
                        <SvgList className={"w-5 h-5 text-slate-700"} />
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm text-slate-800 line-clamp-2">
                          {res.name}
                        </p>
                        <p className="text-xs text-slate-500 line-clamp-2">
                          Club List - Created by {res.creator_name}
                        </p>
                      </div>
                      {renderSelectedIcon(isSelected)}
                    </div>
                  );
                })}
                {clubLists.length === 0 && (
                  <div className="flex flex-col items-center mt-1">
                    <p className="text-sm text-slate-800 line-clamp-2">
                      No club lists for this search
                    </p>
                  </div>
                )}
              </div>
              <HorizontalDivider className="bg-slate-200 my-3" />
              <div>
                <p className="text-sm mb-1 mt-2 font-medium">Student Lists</p>
                {userLists.map((res) => {
                  const isSelected = lists.user.ids.includes(res.id);
                  return (
                    <div
                      key={res.id}
                      className={`flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2`}
                      onClick={() => selectUserList(res)}
                    >
                      <div className="w-8 h-8 flex flex-col items-center justify-center rounded-lg object-cover border border-slate-200">
                        <SvgList className={"w-5 h-5 text-slate-700"} />
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm text-slate-800 line-clamp-2">
                          {res.name}
                        </p>
                        <p className="text-xs text-slate-500 line-clamp-2">
                          Student List - Created by {res.creator_name}
                        </p>
                      </div>
                      {renderSelectedIcon(isSelected)}
                    </div>
                  );
                })}
                {userLists.length === 0 && (
                  <div className="flex flex-col items-center mt-1">
                    <p className="text-sm text-slate-800 line-clamp-2">
                      No student lists for this search
                    </p>
                  </div>
                )}
              </div>
            </CollapseComponent>
          </div>
        </Portal.Root>
      </div>
      {listNames && listNames.length ? (
        <div className="items-center flex flex-row flex-wrap gap-1 mt-2">
          {listNames.map((item, index) => (
            <TagComponent
              key={item.id}
              label={item.name}
              removable
              deleteTag={() => deleteList(item)}
              truncate
              showClose
              className={"p-1"}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}
