import React, {PureComponent} from "react";
import {RECRUITER_NAV_ITEMS} from "../utils/dummy";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import TopNavItem from "./navigation/TopNavItem";
import SvgLogoPrimary from "./icons/SvgLogoPrimary";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
    };
}

class RecruiterNavbar extends PureComponent {
    state = {
        newClubModalVisible: false,
        clubSubmitSuccessVisible: false,
        navbarOpen: false,
        forceClosed: false,
        lastRoute: '/'
    }

    componentDidMount() {
        this.checkRoute()
    }

    componentDidUpdate(prevProps) {
        // Check if the current route path differs from the previous one
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.checkRoute()
        }
    }

    checkRoute = () => {
        this.setState({activeRoute: this.props.location.pathname});
        const path = this.props.location.pathname
        if (path === '/' || path === '/search' || path === '/saved-lists' || path === '/engage' || path === '/company-page' || path === '/settings' || path === '/jobs' || path === '/companies' || path === '/admin') {
            this.setState({lastRoute: path})
        } else if (path.includes('/club') || path.includes('/profile')) {
            this.setState({lastRoute: ''})
        }
    }


    render() {
        const user = this.props.user || {};
        return (
            <div
                className={`flex flex-row justify-between  border-b border-slate-200 p-6 px-5 absolute top-0 right-0 left-0 h-[60px]`}
                style={{
                    transition: 'width 150ms ease-in-out',
                    overflow: "hidden"
                }}
            >
                <div className='flex flex-row items-center'>
                    <Link to={'/'} className={'cursor-pointer'} style={{width: 200}}>
                        <SvgLogoPrimary style={{height: 32, width: 120}}/>
                    </Link>
                    <ul className='flex flex-row items-center gap-1'>
                        {(RECRUITER_NAV_ITEMS.slice(0, 4)).map(item =>
                            <TopNavItem
                                item={item}
                                key={item.label}
                                isActive={this.state.lastRoute === item.route}
                            />
                        )}
                    </ul>
                </div>
                <ul className='ml-auto flex flex-row items-center gap-1'>
                    {(RECRUITER_NAV_ITEMS.slice(4)).map(item => {
                        if (item.canAccess && !item.canAccess(user.id)) {
                            return null;
                        }
                        return (
                            <TopNavItem
                                item={item}
                                key={item.label}
                                isActive={this.state.lastRoute === item.route}
                            />
                        )
                    })}
                </ul>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(RecruiterNavbar));
