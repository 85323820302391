import useQualifiedOnlyState from '../hooks/useQualifiedOnlyState';
import { createContext, useContext } from 'react';

/** @typedef {ReturnType<typeof useQualifiedOnlyState>} QualifiedOnlyState */

/**
 * @type {import('react').Context<QualifiedOnlyState>}
 */
export const QualifiedOnlyContext = createContext(null);

/**
 * @returns {QualifiedOnlyState}
 */
export function useQualifiedOnly() {
  const context = useContext(QualifiedOnlyContext);
  if (!context) {
    throw new Error('useQualifiedOnly must be used within a QualifiedOnlyProvider');
  }
  return context;
}

/**
 * @param {{ children: import('react').ReactNode }} props
 * @returns {import('react').JSX.Element}
 */
export function QualifiedOnlyProvider({ children }) {
  const qualifiedOnlyState = useQualifiedOnlyState();
  
  return (
    <QualifiedOnlyContext.Provider value={qualifiedOnlyState}>
      {children}
    </QualifiedOnlyContext.Provider>
  );
}
