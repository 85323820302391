import React, {Component} from "react";
import {workEnvironmentOptions} from "../../../utils/dummy";
import Switch from "react-switch";
import WrappedTextInput from "../../../components/atoms/WrappedTextInput";
import DatePicker from "react-datepicker";
import SelectComponent from "../../../components/atoms/SelectComponent";
import {STATES} from "../../../utils/constants";

class CreateJobDateLocationStep extends Component {
    render() {
        const STATE_OPTIONS = STATES.map(state => {
            return ({value: state, title: state})
        });
        return (
            <div className='flex flex-col gap-8'>
                {!this.props.hideTutorial &&
                    <p className='text-lg text-slate-800 font-semibold'>
                        Where is this position located and how should candidates apply?
                    </p>
                }
                <div className='flex flex-col gap-3'>
                    <p className='text-md text-slate-500 font-semibold text-slate-800'>
                        Work environment
                    </p>
                    <fieldset className="grid grid-cols-3 gap-4 ml-1">
                        {workEnvironmentOptions.map((option) => (
                            <div key={option.value}>
                                <input
                                    type="radio"
                                    name="locationOption"
                                    value={option.value}
                                    id={option.value}
                                    className="peer hidden [&:checked_+_label_svg]:block"
                                    checked={this.props.workEnvironment === option.value}
                                    onChange={this.props.handleWorkEnvironmentChange}
                                />
                                <label
                                    htmlFor={option.value}
                                    className={`block cursor-pointer rounded-lg border border-gray-100 bg-white p-3 text-sm font-medium shadow-sm hover:border-gray-200 ${
                                        this.props.workEnvironment === option.value
                                            ? "border-blue-500 ring-1 ring-blue-500"
                                            : ""
                                    }`}
                                >
                                    <div className="flex items-center justify-between">
                                        <p className='text-md text-slate-800 font-semibold'>
                                            {option.label}
                                        </p>
                                        <svg
                                            className="hidden h-5 w-5 text-blue-600"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </label>
                            </div>
                        ))}
                    </fieldset>
                    {(this.props.workEnvironment === 'On-site') &&
                        <div className='flex flex-row items-center gap-3'>
                            <div className='flex-1'>
                                <WrappedTextInput
                                    type="text"
                                    outerLabel={'City'}
                                    placeholder={'ie. New York'}
                                    className='input-light px-3 w-full '
                                    value={this.props.city}
                                    onChange={(e) => this.props.handleInputChange(e, 'city')}
                                />
                            </div>
                            <SelectComponent
                                className={'w-1/3'}
                                outerLabel={'State'}
                                label={'ie. NY'}
                                value={this.props.state}
                                light
                                setValue={this.props.handleSelectChange}
                                clearFilter={() => this.props.handleSelectChange('state', null)}
                                stateName={'state'}
                                options={STATE_OPTIONS}
                                scrollable
                                node={document.getElementById('add-joblisting-modal')}
                            />
                        </div>
                    }
                </div>
                <div className='flex flex-col gap-3'>
                    <p className='text-md text-slate-500 font-semibold text-slate-800'>
                        How should candidates apply
                    </p>
                    <fieldset className="grid grid-cols-2 gap-4 ml-1">
                        <div>
                            <input
                                type="radio"
                                name="applyOption"
                                value="external"
                                id="external"
                                className="peer hidden [&:checked_+_label_svg]:block"
                                checked={!this.props.directOnRecruit}
                                onChange={() => this.props.toggleInput('directOnRecruit')}
                            />
                            <label
                                htmlFor="external"
                                className={`block h-[120px] cursor-pointer rounded-lg border border-gray-100 bg-white p-3 text-sm font-medium shadow-sm hover:border-gray-200 ${
                                    !this.props.directOnRecruit ? "border-blue-500 ring-1 ring-blue-500" : ""
                                }`}
                            >
                                <div className="flex h-full flex-col justify-between" onClick={(e) => e.stopPropagation()}>
                                    <div>
                                        <p className='text-md text-slate-800 font-semibold'>
                                            External Application
                                        </p>
                                        <p className='text-sm text-slate-500'>
                                            Students will apply via an external job posting link
                                        </p>
                                    </div>
                                    <div className={`flex flex-row items-center gap-2 ${!this.props.directOnRecruit ? 'opacity-100' : 'opacity-0'} ${this.props.directOnRecruit ? 'pointer-events-none' : ''}`}>
                                        <p className='text-slate-500 text-sm whitespace-nowrap'>
                                            Job posting link:
                                        </p>
                                        <WrappedTextInput
                                            type="text"
                                            placeholder={'https://apply.com/careers'}
                                            className='input-light px-3 w-full'
                                            containerClassName='w-full'
                                            value={this.props.url}
                                            onChange={(e) => this.props.handleInputChange(e, 'url')}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="mr-2">
                            <input
                                type="radio"
                                name="applyOption"
                                value="direct"
                                id="direct"
                                className="peer hidden [&:checked_+_label_svg]:block"
                                checked={this.props.directOnRecruit}
                                onChange={() => this.props.toggleInput('directOnRecruit')}
                            />
                            <label
                                htmlFor="direct"
                                className={`block h-[120px] cursor-pointer rounded-lg border border-gray-100 bg-white p-3 text-sm font-medium shadow-sm hover:border-gray-200 ${
                                    this.props.directOnRecruit ? "border-blue-500 ring-1 ring-blue-500" : ""
                                }`}
                            >
                                <div className="flex h-full flex-col justify-start gap-1">
                                    <p className='text-md text-slate-800 font-semibold'>
                                        Directly On RecruitU
                                    </p>
                                    <p className='text-sm text-slate-500'>
                                        Students will be able to apply directly on RecruitU, and then get routed to your Careers page to fill in the entire application
                                    </p>
                                </div>
                            </label>
                        </div>
                    </fieldset>
                </div>
                <div className='flex flex-col gap-3'>
                    <p className='text-md text-slate-500 font-semibold text-slate-800'>
                        Posting duration
                    </p>
                    <div>
                        <div className='flex flex-row gap-5'>
                            <Switch
                                onColor={'#4848F2'}
                                offColor={'#A3A3A3'}
                                onChange={() => this.props.toggleInput('hasDeadline')}
                                checked={this.props.hasDeadline}
                            />
                            <div>
                                <p className='text-lg text-slate-800 font-semibold'>
                                    Set a deadline
                                </p>
                            </div>
                        </div>
                        {this.props.hasDeadline &&
                            <div className='flex flex-row items-center gap-5'>
                                <p className='ml-76 text-slate-500 text-md'>
                                    Stop accepting applicants on:
                                </p>
                                <DatePicker
                                    className={'px-3 input-light body2 w-full'}
                                    placeholderText={'Due date'}
                                    selected={this.props.deadline}
                                    showYearDropdown
                                    onChange={(e) => this.props.handleDateChange(e, 'deadline')}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateJobDateLocationStep
