import React, { useState } from "react";
import { searchClubs } from "../../utils/typesense";
import MultiSelectWithSearch from "../atoms/MultiSelectWithSearch";
import SvgPeople from "../icons/SvgPeopleFeather";

export default function MultiSearchClubs({ outerLabel, selectedClubs, setSelectedClubs, limit, error, placeholder, colleges=[]}) {
    const [clubSearch, setClubSearch] = useState("");
    const [openCollapse, setOpenCollapse] = useState(false);
    const [clubSearchResults, setClubSearchResults] = useState([]);

    const filteredClubResults = clubSearchResults.filter(
        (result) => !selectedClubs.includes(result.name)
    );

    const handleClubClick = (club) => {
        if (selectedClubs.includes(club)) {
            setSelectedClubs([
                ...new Set(
                    [...selectedClubs].filter((item) => item !== club)
                ),
            ]);
        } else {
            setSelectedClubs([...new Set([...selectedClubs, club])]);
            setClubSearch("");
            setClubSearchResults([]);
            setOpenCollapse(false);
        }
    };

    const handleSearchChange = (event) => {
        const text = event.target.value;
        setClubSearch(text);

        if (text === "") {
            setOpenCollapse(false);
            setClubSearchResults([]);
            return;
        }

        setOpenCollapse(true);
        clubSearchChanged(text);
    };

    const clubSearchChanged = async (text) => {
        const results = await searchClubs(text, 1, 5, colleges.length > 0 ? JSON.stringify(colleges) : null);
        setClubSearchResults(results);
    };

    return (
        <MultiSelectWithSearch
            outerLabel={outerLabel}
            searchValue={clubSearch}
            selected={selectedClubs}
            error={error}
            placeholder={placeholder}
            results={filteredClubResults}
            openCollapse={openCollapse}
            onSearchChange={handleSearchChange}
            onResultClick={handleClubClick}
            onClearAll={() => setSelectedClubs([])}
            resultMapper={(result) => ({
                id: result.id,
                name: result.name,
                imageUrl: result.logo_url,
                value: result.id
            })}
            leftIcon={<SvgPeople className="h-5 w-5 text-slate-500" />}
            maxToShow={0}
        />
    );
}
