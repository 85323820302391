import React, { useState, useEffect } from "react";
import { page_h_padding_class } from "../../utils/constants";
import SvgChevronLeft from "../icons/SvgChevronLeft";
import { getQuestion } from "../../api/student/questions";
import { Button } from "../atoms/Button";
import SvgArrowOutward from "../icons/SvgArrowOutward";
import ModalComponent from "../atoms/modals/ModalComponent";
import { Link } from "react-router-dom";
import { getPublicQuestion } from "../../api/student/questions";
import { ReactMediaRecorder } from "react-media-recorder";
import VideoPreview from "../questionpage/VideoPreview";
import HorizontalDivider from "../atoms/HorizontalDivider";
import QuestionPageInfoSection from "../resources/QuestionPageInfoSection";
import QuestionPageResponsesSection from "../resources/QuestionPageResponsesSection";
import { getInterviewSet } from "../../api/student/interview_sets";
import { useHistory, useParams } from "react-router-dom";
import InterviewSetNavigation from "../resources/InterviewSetNavigation";

const PublicQuestionPage = () => {
    const { questionId } = useParams();
    const [question, setQuestion] = useState({});
    const [interviewSet, setInterviewSet] = useState(null);
    const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
    const history = useHistory();
    

    useEffect(() => {
        fetchData();
    }, [questionId]);

    const fetchData = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const interviewSetId = urlParams.get('interviewSetId');

        const [questionRes, interviewSetRes] = await Promise.all([
            getPublicQuestion(questionId),
            interviewSetId ? getInterviewSet(interviewSetId) : Promise.resolve(null)
        ]);

        setQuestion(questionRes.question);
        setInterviewSet(interviewSetRes);
    };

    const handleSignupClick = () => {
        setIsSignupModalOpen(true);
    };

    const navigateToQuestion = (item) => {
        const urlParams = new URLSearchParams(window.location.search);
        const interviewSetId = urlParams.get('interviewSetId');
        history.push({
            pathname: `/question/${item.id}`,
            search: `?interviewSetId=${interviewSetId}`,
        });
    };

    const nextQuestion = () => {
        if (!interviewSet) return;
        
        const allQuestionIds = interviewSet.questions.map(question => question.id);
        const currentIndex = allQuestionIds.indexOf(question.id);
        if (currentIndex < allQuestionIds.length - 1) {
            navigateToQuestion(interviewSet.questions[currentIndex + 1]);
        }
    };

    const previousQuestion = () => {
        if (!interviewSet) return;
        
        const allQuestionIds = interviewSet.questions.map(question => question.id);
        const currentIndex = allQuestionIds.indexOf(question.id);
        if (currentIndex > 0) {
            navigateToQuestion(interviewSet.questions[currentIndex - 1]);
        }
    };

    return (
        <div className={`flex flex-col pt-5 flex-1 overflow-hidden ${page_h_padding_class}`}>
            <div
                onClick={() => history.goBack()}
                className='ml-[-4px] flex flex-row items-center gap-1 text-slate-500 hover:text-primary cursor-pointer'>
                <SvgChevronLeft/>
                <p>Back</p>
            </div>

            <InterviewSetNavigation 
                interviewSet={interviewSet}
                currentQuestionId={question.id}
                onNavigate={navigateToQuestion}
                onNext={nextQuestion}
                onPrevious={previousQuestion}
            />

            <div className='flex flex-row justify-between mt-7 gap-8 flex-1 overflow-hidden'>
                <div className={'flex flex-col w-full gap-3 pb-5 overflow-y-scroll'}>
                    <QuestionPageInfoSection
                        question={question}
                        initiateResponse={handleSignupClick}
                        onViewAnswer={() => setIsSignupModalOpen(true)}
                        hasResponses={false}
                        answers={[]}
                    />
                    
                    <div className='flex flex-col gap-3 pb-5'>
                        <p className='text-xl font-bold text-slate-900'>
                            Prompt:
                        </p>
                        <p className='text-lg text-slate-600'>
                            {question?.prompt}
                        </p>

                        <ReactMediaRecorder
                            video
                            askPermissionOnMount
                            render={({previewStream}) => {
                                return (
                                    <>
                                        <div
                                            className={`flex flex-col gap-1.5 flex-1 mx-auto overflow-hidden rounded-3xl items-center justify-center relative`}
                                            style={{ maxHeight: "50vh", width:640}}
                                        >
                                            <VideoPreview stream={previewStream}/>
                                        </div>
                                        <div className="items-center justify-center flex flex-col">
                                            <p className='text-xs text-slate-600 mt-8'>
                                                You are not currently being recorded. Please sign up or log in to record your response.
                                            </p>
                                        </div>
                                    </>
                                )
                            }}
                        />
                        <div className='w-full mt-5'>
                            <HorizontalDivider className={'w-full bg-slate-200'}/>
                        </div>
                    </div>

                    <QuestionPageResponsesSection
                        responses={[]}
                        initiateResponse={handleSignupClick}
                    />
                </div>
            </div>

            <ModalComponent
                isOpen={isSignupModalOpen}
                backgroundColor={'white'}
                toggle={() => setIsSignupModalOpen(false)}
                header="Sign up to answer this question"
                description="Create an account or log in to record your response and join our community."
                FooterComponent={
                    <div className='flex flex-row gap-3 w-full'>
                        <Link to={`/signup`} className='flex-1'>
                            <Button className='w-full'>
                                Sign up
                            </Button>
                        </Link>
                        <Link to={`/authentication`} className='flex-1'>
                            <Button variant='secondary' className='w-full'>
                                Log in
                            </Button>
                        </Link>
                    </div>
                }
            />
        </div>
    );
};

export default PublicQuestionPage;
