import React, { useState, useEffect } from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import CreateJobDetailsStep from "./create-job/CreateJobDetailsStep";
import ProgressStepper from "../atoms/ProgressStepper";
import CreateJobDateLocationStep from "./create-job/CreateJobDateLocationStep";
import JobEventQualifications from "./create-event-job/JobEventQualifications";
import {createJob} from "../../api/recruiter/jobs";
import {wait} from "@testing-library/user-event/dist/utils";
import { stateToHTML } from 'draft-js-export-html';
import {Button} from "../../components/atoms/Button";
import {useHistory} from "react-router-dom";
import CreateJobApplicationQuestions from "./create-job/CreateJobApplicationQuestions";
import CreateJobPreviewStep from "./create-job/CreateJobPreviewStep";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {DefaultEditorState} from "../../utils/richEditor";
import { sanitizeHtml } from "../../utils/strings";
import { createJobEventModalParams } from "../../utils/createJobEventModalParams";
import { useQualifiedOnly } from "../../contexts/QualifiedOnlyContext";

const ZERO_STATE = {
    // Form navigation
    step: 1,
    numSteps: 5,
    stepLabels: ['Job Details', 'Date and Location', 'Questions', 'Candidate Qualification', 'Preview'],
    loading: false,
    showConfirmation: false,

    // Basic job details
    jobId: uuidv4(),
    jobTitle: '',
    jobDescription: '',
    jobType: null,
    role: null,
    openings: null,
    editorState: DefaultEditorState,
    jobPdfPath: null,
    categories: [],

    // Location and work model
    workEnvironment: "On-site",
    city: '',
    state: '',

    // Application settings
    directOnRecruit: false,
    url: '',
    applicationQuestions: [],
    qualifiedOnly: true,

    // Posting schedule
    postToday: true,
    postOn: null,
    hasDeadline: true,
    deadline: null,
};

const CreateJobModal = ({ isOpen, closeModal, authUser, tier, constants, currentClubs }) => {
    const {filters, selectionMode, lists} = useQualifiedOnly()
    const [state, setState] = useState(ZERO_STATE);
    const history = useHistory();

    const submit = async () => {
        setState(prev => ({...prev, loading: true}));
        const contentState = state.editorState.getCurrentContent();
        const htmlContent = sanitizeHtml(stateToHTML(contentState));
        
        const params = createJobEventModalParams(
            { 
                ...state, 
                htmlContent,
                filters,
                selectionMode,
                lists
            },
            'job',
            tier
        );

        toast.promise(createJob(authUser, params).then(() => {
            closeModal();
            window.analytics.track("New Recruiter Job Created");
            setState(prev => ({...prev, loading: false}));
        }).finally(() => {
            history.push("/jobs");
        }), {
            pending: "Creating job...",
            success: "Job created successfully!",
            error: "Failed to create job."
        });
    };

    const nextStep = () => {
        if (state.step === state.numSteps) {
            setState(prev => ({ ...prev, showConfirmation: true }));
            return;
        }
        setState(prev => ({...prev, step: prev.step + 1}));
    };

    const prevStep = () => {
        if (state.step === 1) {
            close();
            return;
        }
        setState(prev => ({...prev, step: prev.step - 1}));
    };

    const handleInputChange = (e, inputName) => {
        setState(prev => ({...prev, [inputName]: e.target.value}));
    };

    const handleSelectChange = (inputName, value) => {
        setState(prev => ({...prev, [inputName]: value}));
    };

    const handleWorkEnvironmentChange = (event) => {
        setState(prev => ({...prev, workEnvironment: event.target.value}));
    };

    const handleQualifiedOnlyChange = (boolean) => {
        setState(prev => ({...prev, qualifiedOnly: boolean}));
    };

    const toggleInput = (stateKey) => {
        setState(prev => ({...prev, [stateKey]: !prev[stateKey]}));
    };

    const handleDateChange = (e, stateKey) => {
        setState(prev => ({...prev, [stateKey]: e}));
    };

    const onJobPdfUpload = (path) => {
        setState(prev => ({...prev, jobPdfPath: path}));
    };

    const updateApplicationQuestions = (text, i) => {
        setState(prev => {
            const applicationQuestions = [...prev.applicationQuestions];
            if (i >= applicationQuestions.length) {
                applicationQuestions.push({text, type: "input"});
            } else {
                applicationQuestions[i] = {text, type: "input"};
            }
            return {...prev, applicationQuestions};
        });
    };

    useEffect(() => {
        if (tier === 'blk') {
            setState(prev => ({
                ...prev,
                numSteps: 3,
                stepLabels: ['Job Details', 'Date and Location', 'Questions'],
            }));
        }
    }, [tier]);

    useEffect(() => {
        if (isOpen) {
            wait(10).then(() => {
                setState(prev => ({
                    ...prev,
                    step: 1,
                    clubs: currentClubs,
                    jobId: uuidv4()
                }));
            });
        }
    }, [isOpen, currentClubs]);

    const switchDisabled = () => {
        switch (state.step) {
            case 0:
                return true;
            case 1:
                return !state.jobTitle || !state.jobType || !state.role;
            case 2:
                return (state.workEnvironment !== "Remote" ? (!state.city || !state.state) : false) || 
                       (state.hasDeadline ? !state.deadline : false) ||
                       (!state.directOnRecruit && !state.url);
            default:
                return false;
        }
    };

    const handleConfirmSubmit = () => {
        setState(prev => ({ ...prev, showConfirmation: false }));
        submit();
    }

    const handleCancelSubmit = () => {
        setState(prev => ({ ...prev, showConfirmation: false }));
    }

    const close = () => {
        setState(ZERO_STATE);
        closeModal();
    };

    const setEditorState = (editorState) => {
        setState(prev => ({...prev, editorState}));
    };

    return (
        <>
            <ModalComponent
                isOpen={isOpen}
                backgroundColor={'white'}
                header={'Create A New Job'}
                headerToggle
                size={'lg'}
                toggle={close}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button variant={'secondary'} onClick={prevStep}>
                            {state.step === 1 ? "Cancel" : "Back"}
                        </Button>
                        <Button onClick={nextStep} disabled={switchDisabled()} loading={state.loading}>
                            {state.step !== state.numSteps ? "Next" : "Submit"}
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
                showScrollBar
            >
                <div id={'add-joblisting-modal'}>
                    <ProgressStepper
                        step={state.step}
                        stepLabels={state.stepLabels}
                    />
                
                    {state.step === 1 &&
                        <CreateJobDetailsStep
                            jobTitle={state.jobTitle}
                            jobDescription={state.jobDescription}
                            jobType={state.jobType}
                            role={state.role}
                            openings={state.openings}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            editorState={state.editorState}
                            setEditorState={setEditorState}
                            constants={constants}
                            onJobPdfUpload={onJobPdfUpload}
                            jobPdfPath={state.jobPdfPath}
                            jobId={state.jobId}
                            selectionMode={state.selectionMode}
                            setSelectionMode={state.setSelectionMode}
                        />
                    }
                    {state.step === 2 &&
                        <CreateJobDateLocationStep
                            jobTitle={state.jobTitle}
                            jobDescription={state.jobDescription}
                            handleInputChange={handleInputChange}
                            handleSelectChange={handleSelectChange}
                            workEnvironment={state.workEnvironment}
                            city={state.city}
                            state={state.state}
                            url={state.url}
                            toggleInput={toggleInput}
                            directOnRecruit={state.directOnRecruit}
                            postToday={state.postToday}
                            postOn={state.postOn}
                            hasDeadline={state.hasDeadline}
                            deadline={state.deadline}
                            handleDateChange={handleDateChange}
                            handleWorkEnvironmentChange={handleWorkEnvironmentChange}
                        />
                    }
                    
                    {state.step === 3 &&
                        <CreateJobApplicationQuestions
                            applicationQuestions={state.applicationQuestions}
                            updateApplicationQuestions={updateApplicationQuestions}
                        />
                    }
                    {state.step === 4 &&
                        <JobEventQualifications
                            qualifiedOnly={state.qualifiedOnly}
                            handleQualifiedOnlyChange={handleQualifiedOnlyChange}
                        />
                    }
                    {state.step === 5 &&
                        <CreateJobPreviewStep
                            listingState={{
                                name: state.jobTitle,
                                city: state.city,
                                state: state.state,
                                link: state.url,
                                created_at: new Date().toISOString(),
                                type: state.jobType,
                                secondary_specialties: [state.role],
                                industry: state.role?.endsWith('Consulting') ? 'Consulting' : 'Finance',
                                deadline: state.hasDeadline ? state.deadline.toISOString() : null,
                                work_model: state.workEnvironment,
                                whitelisted: false,
                                exclusive: true,
                                tags: [], 
                                description: sanitizeHtml(stateToHTML(state.editorState.getCurrentContent())),
                                application_questions: state.applicationQuestions
                            }}
                        />
                    }
                </div>
            </ModalComponent>
            <ModalComponent
                isOpen={state.showConfirmation}
                backgroundColor={'white'}
                header={'Confirm Job Posting'}
                size={'sm'}
                toggle={handleCancelSubmit}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button variant={'secondary'} onClick={handleCancelSubmit}>
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmSubmit} loading={state.loading}>
                            Confirm
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <div className="p-4 text-center">
                    <p className="mb-4">Are you sure you want to post this job?</p>
                    <p className="text-gray-600">This job will be immediately visible to students on the platform.</p>
                </div>
            </ModalComponent>
        </>
    );
};

export default CreateJobModal;
