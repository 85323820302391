import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import getEnv from './utils/env';

const env = getEnv()

Sentry.init({
  enabled: env !== 'local', // Disable Sentry completely in local environment
  dsn: "https://7ab01987a13bf8f1058c29f60a68c962@o4508679801143296.ingest.us.sentry.io/4508700587327488",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost:5001",
    "recruit-u-f79a8.uc.r.appspot.com",
    "dev-dot-recruit-u-f79a8.uc.r.appspot.com"
  ],
  // Session Replay
  replaysSessionSampleRate: env === 'local' ? 0 : 0.1, // Disable replay in local, 10% sample rate otherwise
  replaysOnErrorSampleRate: env === 'local' ? 0 : 1.0, // Disable error replay in local, 100% sample rate otherwise
  environment: env,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
