import React, {Component, createRef, useRef, useEffect, useState} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {connect} from "react-redux";
import {Button} from "../../components/atoms/Button";
import SvgPlayArrow from "../icons/SvgPlayArrow";
import {ReactMediaRecorder} from "react-media-recorder";
import {uploadVideo} from '../../api/student/video';
import {v4 as uuid} from 'uuid';
import {gradeAnswer, createAnswer} from "../../api/student/answers";
import SvgRecord from "../icons/SvgRecord";
import {DateTime} from 'luxon';
import HorizontalDivider from "../atoms/HorizontalDivider";
import SvgTrash from "../icons/SvgTrash";
import SvgRestart from "../icons/SvgRestart";
import SvgPause from "../icons/SvgPause";
import SvgCheck from "../icons/SvgCheck";
import Dictaphone from "../questionpage/Dictaphone";
import Stopwatch from "../questionpage/Stopwatch";
import VideoPreview from "../questionpage/VideoPreview";
import SvgCelebration from "../icons/SvgCelebration";
import SvgThumbUp from "../icons/SvgThumbUp";
import SvgThumbDown from "../icons/SvgThumbDown";
import { getQualitativeRating } from "../../utils/stats";
import { submitAnswerFeedback } from "../../api/student/answers";
import { isFeatureEnabled } from "../../config/featureFlags";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        answerIds: state.userReducer.answerIds
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setAnswerIds: (answerIds) => {
            dispatch({
                type: "SET_ANSWER_IDS",
                answerIds,
            })
        },
    }
}


const ZERO_STATE = {
    isVideoPlaying: false,
    isRecording: false,
    finishedRecording: false,
    isStarting: false,
    isSubmitProcessing: false,
    preVideoStarting: true,
    countdown: 3,
    transcript: "",
    startTime: 0,
    errorMessage: "",
    showFeedbackModal: false,
    answerScore: null,
    selectedFeedback: null,
    additionalFeedback: "",
    answerId: null,
}

class RecordingViewModal extends Component {
    state = ZERO_STATE
    countdownTimer = null;
    videoRef = createRef();

    stopBothVideoAndAudio(stream) {
        stream.getTracks().forEach((track) => {
            if (track.readyState == 'live') {
                track.stop();
            }
        });
    }

    checkPermissions = async () => {
        try {
          // Check if permissions API is supported
          if (!navigator.permissions) {
            this.setState({errorMessage: "Your browser does not support this feature."})
            return false
          }

          const microphonePermission = await navigator.permissions.query({
            name: 'microphone'
          });
          const cameraPermission = await navigator.permissions.query({
            name: 'camera'
          });

          console.log(cameraPermission, microphonePermission)
          if (microphonePermission.state === 'granted' && cameraPermission.state === 'granted') {
            console.log('Microphone and camera permissions are already granted');
            return true;
          } else {
            // Permissions need to be requested
            console.log('Microphone and camera permissions need to be requested');
            navigator.mediaDevices.getUserMedia({ video: true, audio: true }, (stream) => this.stopBothVideoAndAudio(stream));

            this.setState({errorMessage: "Please accept camera and microphone permissions then click 'Start Recording'."})
            return false;

          }
        } catch (error) {
          // Handle error - Permissions API not supported
          console.error("Error checking permissions:", error);
          this.setState({errorMessage: "Permissions error."})
          return false
        }
      };

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState(ZERO_STATE);
            this.countdownTimer = null;
            this.videoRef = createRef();
        }
    }

    setTranscript = (transcript) => {
        this.setState({transcript})
    }

    togglePlay = () => {
        const video = this.videoRef.current;
        if (video.paused) {
            video.play();
            this.setState({isVideoPlaying: true, firstPlay: true})
        } else {
            video.pause();
            this.setState({isVideoPlaying: false})
        }
    }

    startRecording = async (startRecording) => {
        if (await this.checkPermissions()) {
            this.setState({...ZERO_STATE, isStarting: true, preVideoStarting: false})
            this.countdownTimer = setInterval(() => {
                this.setState(prevState => ({countdown: prevState.countdown - 1}), () => {
                    if (this.state.countdown === 0) {
                        startRecording();
                        clearInterval(this.countdownTimer);
                        this.setState({isStarting: false, isRecording: true, startTime: DateTime.now(), errorMessage: ""});

                        const questionType = (!!this.props.interviewSet) ? "Interview" : "Practice";
                        window.analytics.track(`${questionType} Question Started`, {
                          question_id: this.props.question.id,
                          ...(this.props.interviewSet && {interview_id: this.props.interviewSet.id})
                        })
                    }
                });
            }, 1000);
        }
    }

    cancelRecording = () => {
        this.setState(ZERO_STATE);
    }


    finishRecording = () => {
        this.setState({finishedRecording: true, isRecording: false});
    }

    submitRecording = async (mediaBlobUrl) => {
        this.setState({isSubmitProcessing: true});

        const endTime = DateTime.now()
        const videoLength = endTime.diff(this.state.startTime, 'seconds').seconds;

        const url = await this.submitVideo(mediaBlobUrl);
        const hasCompletedSet = this.props.interviewSet?.question_ids?.every(id => this.props.answerIds.includes(id));
        const answer = await createAnswer(this.props.authUser, {
            user_id: this.props.authUser.uid,
            question_id: this.props.question.id,
            video_url: url,
            transcript: this.state.transcript,
            video_length: videoLength,
        })
        const answerIds = [...this.props.answerIds ?? []];
        answerIds.push(answer.question_id);
        this.props.setAnswerIds(answerIds);
        this.setState({answerId: answer.id});
        this.props.getInfo();
        
        const gradeResponse = await gradeAnswer(this.props.authUser, answer.id);
        this.props.getInfo();
        
        this.setState({
            isSubmitProcessing: false, 
            showFeedbackModal: isFeatureEnabled('LEARN_OPTIMIZATION'),
            answerScore: gradeResponse?.grade_res?.score ?? null
        });

        const questionType = (!!this.props.interviewSet) ? "Interview" : "Practice";
        window.analytics.track(`${questionType} Question Completed`, {
            question_id: this.props.question.id,
            ...(this.props.interviewSet && {interview_id: this.props.interviewSet.id})
        })

        if (!hasCompletedSet && !!this.props.interviewSet && this.props.interviewSet.question_ids.every(id => this.props.answerIds.includes(id)))
            window.analytics.track("Interview Set Completed", {
            interview_id: this.props.interviewSet.id
        })

    }

    submitVideo = async (mediaBlobUrl) => {
        const blob = await fetch(mediaBlobUrl).then(r => r.blob());
        const file = new File([blob], "videofile.mp4", {type: "video/mp4"})

        const videoUrl = await uploadVideo(file, uuid());
        return videoUrl;
    };

    handleFeedbackSelection = (feedback) => {
        this.setState({ selectedFeedback: feedback });
    }

    handleFeedbackSubmit = async () => {
        const { selectedFeedback, additionalFeedback } = this.state;
        
        try {
            await submitAnswerFeedback(
                this.props.authUser,
                this.state.answerId,
                selectedFeedback,
                additionalFeedback
            );
            
            this.setState({ showFeedbackModal: false });
            this.props.closeModal();
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
    }

    render() {
        return (
            <>
                <ModalComponent
                    isOpen={this.props.isOpen}
                    backgroundColor={'white'}
                    header={this.props.title}
                    size={'full'}
                    toggle={() => {
                        this.cancelRecording();
                        this.props.closeModal()
                    }}
                >
                    <div
                        className='absolute text-6xl font-bold flex flex-col items-center justify-center left-0 right-0 top-0 bottom-0'>
                        <ReactMediaRecorder
                            video
                            askPermissionOnMount
                            render={({status, startRecording, stopRecording, mediaBlobUrl}) => {
                                let footerContent;
                                if (this.state.isStarting) {
                                    footerContent = (
                                        <div className='h-10'/>
                                    );
                                } else if (this.state.isRecording) {
                                    footerContent = (
                                        <div className='flex flex-row relative items-center gap-3 justify-center w-full'>
                                            <div
                                                className='absolute left-0 right-0 -top-12 justify-center items-center flex flex-row'>
                                           <span className="relative flex h-3 w-3">
                                                  <span
                                                      className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                                  <span
                                                      className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                                </span>
                                                <p className='ml-2 text-xl'>
                                                    <Stopwatch
                                                        isRecording={this.state.isRecording}
                                                        finishRecording={this.finishRecording}
                                                        stopRecording={stopRecording}
                                                    />
                                                </p>
                                            </div>
                                            <Button variant='secondary' onClick={() => {
                                                stopRecording();
                                                this.cancelRecording()
                                            }} icon={SvgTrash} iconPosition={'left'}>
                                                Exit
                                            </Button>
                                            <Button variant='secondary' onClick={() => {
                                                stopRecording();
                                                this.startRecording(startRecording)
                                            }} icon={SvgRestart} iconPosition={'left'}>
                                                Restart
                                            </Button>
                                            <Button onClick={() => {
                                                stopRecording();
                                                this.finishRecording()
                                            }}>
                                                Finish Recording
                                            </Button>
                                        </div>
                                    );
                                } else if (this.state.finishedRecording) {
                                    footerContent = (
                                        <div className='flex flex-row items-center gap-3 justify-center w-full z-10'>
                                            <Button
                                                disabled={this.state.isSubmitProcessing}
                                                onClick={this.props.closeModal} variant='secondary'>
                                                Exit
                                            </Button>
                                            <Button
                                                disabled={this.state.isSubmitProcessing}
                                                onClick={() => this.startRecording(startRecording)} variant='secondary'
                                                icon={SvgRestart} iconPosition={'left'}>
                                                Redo
                                            </Button>
                                            <Button
                                                disabled={this.state.isSubmitProcessing}
                                                onClick={() => this.submitRecording(mediaBlobUrl)}
                                                icon={SvgCheck} iconPosition={'left'}>
                                                {this.state.isSubmitProcessing ? 'Processing' : 'Submit Response'}
                                            </Button>
                                        </div>
                                    );
                                } else {
                                    footerContent = (
                                        <div className='flex flex-row items-center gap-3 justify-center w-full'>
                                            <Button onClick={this.props.closeModal} variant='secondary'>
                                                Cancel
                                            </Button>
                                            <Button onClick={() => {
                                                this.startRecording(startRecording);
                                            }} icon={SvgRecord} iconPosition={'left'}>
                                                Start Recording
                                            </Button>
                                        </div>
                                    );
                                }
                                return (
                                    <>

                                        <div className='flex flex-col gap-2 pb-5 mt-24 mb-24 text-center'>
                                            <p className='text-xl font-bold text-slate-900'>
                                                Prompt:
                                            </p>
                                            <p className='text-lg text-slate-600'>
                                                {this.props.question?.prompt}
                                            </p>
                                        </div>

                                        <div
                                            className={`flex flex-col gap-1.5 flex-1 mx-auto overflow-hidden rounded-3xl bg-slate-100 items-center justify-center relative`}
                                            style={{ maxHeight: "50vh", width:640}}
                                        >
                                            {!this.state.finishedRecording &&
                                                <VideoPreview stream={this.props.previewStream}/>
                                            }
                                            {this.state.finishedRecording &&
                                                <video ref={this.videoRef} src={mediaBlobUrl} className="w-full object-cover h-full scale-x-[-1]"/>
                                            }
                                            {(this.state.isStarting) &&
                                                <div
                                                    onClick={this.togglePlay}
                                                    style={{color: "white"}}
                                                    className='absolute text-6xl font-bold flex flex-col items-center justify-center left-0 right-0 top-0 bottom-0'>
                                                    <p className='text-sm font-semibold'>
                                                        Starting in
                                                    </p>
                                                    <p>
                                                        {this.state.countdown}
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                        {this.state.finishedRecording ?
                                            <div
                                                onClick={this.togglePlay}
                                                className='absolute flex flex-col items-center justify-center left-0 right-0 top-0 bottom-0'>
                                                <Button size={'icon'}>
                                                    {this.state.isVideoPlaying ? <SvgPause/> : <SvgPlayArrow/>}
                                                </Button>
                                            </div>
                                            : null
                                        }

                                        <div
                                            style={{padding: this.props.noPadding && `0 ${24}px ${24}px`}}
                                            className={"flex flex-row pt-16 mt-48 mb-8"}>
                                            {footerContent}
                                        </div>
                                        {this.state.errorMessage &&
                                            <p className="text-lg text-slate-600">
                                               {this.state.errorMessage}
                                            </p>
                                        }
                                        <Dictaphone isRecording={this.state.isRecording}
                                                    setTranscript={this.setTranscript}/>
                                    </>
                                )
                            }}
                        />
                    </div>

                </ModalComponent>

                <ModalComponent
                    isOpen={this.state.showFeedbackModal}
                    toggle={() => this.setState({showFeedbackModal: false})}
                    header="  "
                    size="sm"
                    backgroundColor="white"
                >
                    <div className="flex flex-col items-center gap-6 py-4">
                        {/* Score Display Section */}
                        <div className="flex flex-col items-center gap-2">
                            <p className="text-lg text-slate-600">Your Score:</p>
                            <div className={`text-2xl font-bold flex items-center gap-2 ${getQualitativeRating(this.state.answerScore)?.color}`}>
                                {getQualitativeRating(this.state.answerScore)?.text}
                                {getQualitativeRating(this.state.answerScore)?.icon === 'celebration' && <SvgCelebration className="w-6 h-6" />}
                                {getQualitativeRating(this.state.answerScore)?.icon === 'thumbs-up' && <SvgThumbUp className="w-6 h-6" />}
                                {getQualitativeRating(this.state.answerScore)?.icon === 'thumbs-down' && <SvgThumbDown className="w-6 h-6" />}
                            </div>
                        </div>

                        <HorizontalDivider />

                        {/* Feedback Section */}
                        <div className="flex flex-col items-center gap-4">
                            <p className="text-lg text-slate-600">Was this response helpful?</p>
                            
                            <div className="flex gap-6">
                                <button 
                                    onClick={() => this.handleFeedbackSelection('bad')}
                                    className={`p-3 rounded-full hover:bg-slate-100 transition-colors ${
                                        this.state.selectedFeedback === 'bad' 
                                        ? 'bg-slate-100 text-slate-600' 
                                        : 'text-slate-400'
                                    }`}
                                >
                                    <SvgThumbDown className="w-8 h-8" />
                                </button>
                                <button
                                    onClick={() => this.handleFeedbackSelection('good')}
                                    className={`p-3 rounded-full hover:bg-slate-100 transition-colors ${
                                        this.state.selectedFeedback === 'good' 
                                        ? 'bg-slate-100 text-slate-600' 
                                        : 'text-slate-400'
                                    }`}
                                >
                                    <SvgThumbUp className="w-8 h-8" />
                                </button>
                            </div>

                            <textarea
                                className="w-full p-2 border border-slate-300 rounded-md mt-4"
                                placeholder="Additional feedback (optional)"
                                rows={4}
                                value={this.state.additionalFeedback}
                                onChange={(e) => this.setState({additionalFeedback: e.target.value})}
                            />

                            <Button
                                onClick={this.handleFeedbackSubmit}
                                className="w-full"
                                disabled={!this.state.selectedFeedback}
                            >
                                Submit Feedback
                            </Button>
                        </div>
                    </div>
                </ModalComponent>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordingViewModal);
