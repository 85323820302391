import React from "react";
import MultiSelectComponent from "../atoms/MultiSelectComponent";

const RACE_OPTIONS = [
    { title: "White" },
    { title: "Hispanic or Latino" },
    { title: "Black or African American" },
    { title: "American Indian or Native American" },
    { title: "Asian or Pacific Islander" },
    { title: "Other" },
    { title: "Prefer Not to Specify" },
];

const MultiSearchRace = ({ selectedValues, setSelectedValues, className, hasCollapse }) => {
    const clearFilter = () => setSelectedValues([]);

    const handleSetValue = (value) => {
        if (!value) return;

        const newValues = [...selectedValues];
        const index = newValues.findIndex(v => v === value);

        if (index >= 0) {
            newValues.splice(index, 1);
        } else {
            newValues.push(value);
        }

        setSelectedValues(newValues);
    };

    return (
        <MultiSelectComponent
            className={className}
            // outerLabel="Race/Ethnicity"
            label="Select race/ethnicity"
            value={selectedValues}
            light
            setValue={handleSetValue}
            clearFilter={clearFilter}
            options={RACE_OPTIONS}
            scrollable={true}
            hasCollapse={hasCollapse}
        />
    );
};

export default MultiSearchRace;
