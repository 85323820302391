import React, {useState, useEffect} from "react";
import RecruiterDashboardTable from "../dashboard/RecruiterDashboardTable";
import {APPLICANTS_TABLE_HEADERS_FULLPAGE, MAJOR_OPTIONS} from "../../utils/dummy";
import RecruiterJobApplicantTableItem from "../RecruiterJobApplicantTableItem";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import GoBack from "../../components/atoms/GoBack";
import {getJob, deleteJob} from "../../api/recruiter/jobs";    
import {getRecruiterJobApplications} from "../../api/recruiter/applications";
import {connect} from "react-redux";
import RecruiterEditJobModal from "../modals/RecruiterEditJobModal";
import Spinner from "../../components/Spinner";
import { DateTime } from 'luxon';
import {Button} from "../../components/atoms/Button";
import SvgSchool from "../../components/icons/SvgSchool";
import CollapseComponent from "../../components/atoms/CollapseComponent";
import { filteredCollegeSearch } from "../../utils/typesense";
import {toast} from "react-toastify";
import {LinkIcon} from "@heroicons/react/24/outline";
import {TOAST_OPTIONS} from "../../utils/constants";
import RecruiterBulkCreateList from "../RecruiterBulkCreateList";
import FiltersSidebar from "../atoms/FiltersSidebar";
import MultiSearchRace from "../../components/search/MultiSearchRace";
import SchoolLogo from "../../components-recruiter/atoms/SchoolLogo";
import MajorAdvancedSelector from "../atoms/MajorAdvancedSelector";
import { QualifiedOnlyProvider } from "../../contexts/QualifiedOnlyContext";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        studentPeekOpen: state.userReducer.studentPeekOpen,
        candidates: state.userReducer.candidates,
        constants: state.userReducer.constants
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        setCandidates: (candidates) => {
            dispatch({
                type: "SET_CANDIDATES",
                candidates,
            })
        },
    }
}

function RecruiterJobListingPage(props) {
    const [job, setJob] = useState({});
    const [applications, setApplications] = useState([]);
    const [applicationObjects, setApplicationObjects] = useState({});
    const [year, setYear] = useState([]);
    const [major, setMajor] = useState(new Set());
    const [gpa, setGpa] = useState("");
    const [loading, setLoading] = useState(true);
    const [editJobModalOpen, setEditJobModalOpen] = useState(false);
    const [collegeOptions, setCollegeOptions] = useState([]);
    const [college, setCollege] = useState([]);
    const [schoolSearch, setSchoolSearch] = useState("");
    const [openSchoolCollapse, setOpenSchoolCollapse] = useState(false);
    const [schoolSearchResults, setSchoolSearchResults] = useState([]);
    const [search, setSearch] = useState("");
    const [selectedApplications, setSelectedApplications] = useState(new Set());
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [race, setRace] = useState([]);
    const [gender, setGender] = useState("");
    const [veteran, setVeteran] = useState(false);
    const [lgbtq, setLgbtq] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [sortStates, setSortStates] = useState({
        name: 0,
        applied_at: 1
    });

    const candidates = React.useMemo(() => Object.values(props.candidates) || [], [props.candidates]);

    const filteredApplications = React.useMemo(() => {
        const sortedCandidates = candidates.filter(user => {
            let nameMatch = true;
            if (!!search) {
                const fullName = user.first_name + " " + user.last_name;
                const lowerCaseName = fullName.toLowerCase();
                nameMatch = lowerCaseName.startsWith(search.toLowerCase());
            }

            let majorMatch = true;
            if (major.size > 0) {
                majorMatch = user.major.some(m => major.has(m));
            }
            let yearMatch = true;
            if (!!year.length) {
                yearMatch = year.includes(user.grade);
            }
            let collegeMatch = true;
            if (!!college.length) {
                collegeMatch = college.includes(user.college);
            }
            let gpaMatch = true;
            if (!!gpa) {
                gpaMatch = user.gpa >= gpa;
            }
            let raceMatch = true;
            if (!!race.length) {
                raceMatch = user.diversity?.race?.some(r => race.includes(r));
            }
            let genderMatch = true;
            if (!!gender) {
                genderMatch = user.diversity?.gender === gender;
            }
            let veteranMatch = true;
            if (!!veteran) {
                veteranMatch = user.diversity?.veteran === "Yes";
            }
            let lgbtqMatch = true;
            if (!!lgbtq) {
                lgbtqMatch = user.diversity?.lgbtq === "Yes";
            }
            let disabledMatch = true;
            if (!!disabled) {
                disabledMatch = user.diversity?.disabled === "Yes";
            }
            let authorizedMatch = true;
            if (!!authorized) {
                authorizedMatch = user.diversity?.authorized === "Yes";
            }
            return nameMatch && majorMatch && yearMatch && collegeMatch && gpaMatch && applications.includes(user.username) && raceMatch && genderMatch && veteranMatch && lgbtqMatch && disabledMatch && authorizedMatch;
        });

        const activeSortKey = Object.entries(sortStates).find(([_, value]) => value > 0)?.[0];
        if (activeSortKey) {
            sortedCandidates.sort((a, b) => {
                let comparison;
                if (activeSortKey === 'name') {
                    const aName = `${a.first_name} ${a.last_name}`;
                    const bName = `${b.first_name} ${b.last_name}`;
                    comparison = aName.localeCompare(bName);
                    return sortStates[activeSortKey] === 1 ? comparison : -comparison;
                } else if (activeSortKey === 'applied_at') {
                    const aDate = applicationObjects[a.username]?.created_at || '0';
                    const bDate = applicationObjects[b.username]?.created_at || '0';
                    comparison = new Date(bDate) - new Date(aDate);
                    return sortStates[activeSortKey] === 1 ? comparison : -comparison;
                }
            });
        }

        return sortedCandidates;
    }, [candidates, search, major, year, college, gpa, applications, race, gender, veteran, lgbtq, disabled, authorized, sortStates, applicationObjects]);

    useEffect(() => {
        getJobInfo();
    }, []);

    useEffect(() => {
        if (!props.location?.params?.forceRefresh && props.location?.params?.forceRefresh) {
            getJobInfo();
        }
    }, [props.location?.params?.forceRefresh]);

    const getJobInfo = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const jobId = lastArg.split("?")[0];
        const res = await getJob(props.authUser, jobId);

        const result = await getRecruiterJobApplications(props.authUser, jobId);

        const candidates = result.applications.map(application => application.user)
        props.setCandidates(candidates);

        const colleges = new Set(candidates.filter(student => !!student.college).map(student => student.college));
        const lis = Array.from(colleges).sort();
        const applicationObjectsMap = Object.fromEntries(
            result.applications.map(application => [application.user_id, application])
        );
        setApplicationObjects(applicationObjectsMap);


        setJob(res.job);
        setApplications(result.applications.map(application => application.user_id));
        setCollegeOptions(lis.map(college => ({title: college})));
        setLoading(false);
    }

    const deleteStudentJob = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const jobId = lastArg.split("?")[0];
        await deleteJob(props.authUser, jobId);
        props.history.goBack()
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSchoolSearchChange = (event) => {
        const text = event.target.value;
        if (!text) {
            setSchoolSearch(text);
            setOpenSchoolCollapse(false);
            handleCollegeChange(null);
            return;
        }
        setSchoolSearch(text);
        setOpenSchoolCollapse(true);
        schoolSearchChanged(text);
    }

    const schoolSearchChanged = async (text) => {
        const results = await filteredCollegeSearch(text);
        setSchoolSearchResults(results);
    }

    const handleYearChange = (year) => {
        if (year === null) {
            setYear([]);
            return;
        }

        setYear(prev => {
            if (prev.includes(year)) {
                return prev.filter(y => y !== year);
            }
            return [...prev, year];
        });
    }

    const handleCollegeChange = (selectedCollege) => {
        if (selectedCollege === null) {
            setCollege([]);
            return;
        }
        setCollege([selectedCollege]);
    }

    const handleMajorChange = (newMajorSet) => {
        setMajor(newMajorSet);
    };

    const toggleEditJobModal = () => {
        setEditJobModalOpen(prev => !prev);
    }

    const handleGpaChange = (gpa) => {
        setGpa(gpa);
    }

    const downloadCsv = () => {
        const candidates = Object.values(props.candidates) || [];
        const applicants = candidates.filter(user => applications.includes(user.username));
        const end = applicants.map(row => {
            const answers = job?.application_questions.length 
                ? Object.values(applicationObjects[row.username]["answers"])
                    .map(answer => `"${answer.replace(/"/g, '""').replace(/\n/g, ' ')}"`).join(",")
                : "";
        
            return `${row.first_name} ${row.last_name},${row.email},${row.grade},${row.college},${row.gpa},${row?.major.join("/")},${row?.minor?.join("|")},${row?.diversity?.authorized},${row.diversity?.disabled},${row.diversity?.first_generation},${row.diversity?.gender},${row.diversity?.race?.join("|")},${row.diversity?.require_sponsorship},${row.diversity?.veteran},${answers}`.replace(/\n/g, ' ')
        }).join('\n');
        const csv = `name,email,class year,college,gpa,majors,minors,authorized to work,disability,first generation,gender,race,requires sponsorship,veteran,${job?.application_questions.length ? job.application_questions.map(q => q.text).join(","): ""}\n` + end;

        const blob = new Blob([csv], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'applicants.csv');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const previewJob = () => {
        props.history.push(`/job/${job.id}`);
    }

    const handleSelectStudentChange = (student) => {
        const newSelectedApplications = new Set(selectedApplications);
        if (newSelectedApplications.has(student.id)) {
            newSelectedApplications.delete(student.id);
        } else {
            newSelectedApplications.add(student.id);
        }
        setSelectedApplications(newSelectedApplications);
    };

    const toggleSelectAll = () => {
        const newState = !selectAllChecked;
        setSelectAllChecked(newState);
        if (newState) {
            setSelectedApplications(new Set(filteredApplications.map(application => application.id)));
        } else {
            setSelectedApplications(new Set());
        }
    };

    const updateRace = (race) => {
        setRace(race);
    }

    const updateDiversity = (field, value) => {
        value = value ? value : null;

        if (field === "gender") setGender(value);
        else if (field === "veteran") setVeteran(value);
        else if (field === "lgbtq") setLgbtq(value);
        else if (field === "disabled") setDisabled(value);
        else if (field === "authorized") setAuthorized(value);
    }

    const handleSort = (key) => {
        const newSortStates = { ...sortStates };
        Object.keys(newSortStates).forEach(k => {
            if (k !== key) newSortStates[k] = 0;
        });
        newSortStates[key] = (newSortStates[key] + 1) % 3;
        setSortStates(newSortStates);
    };

    return (
        <div className={`flex flex-col flex-1 p-[12px] gap-2 overflow-hidden`}>
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row justify-between items-start gap-12'>
                    <div className='flex flex-col gap-1 flex-1'>
                        <GoBack/>
                        <div className="flex flex-row">
                            <p className='text-2xl font-semibold'>
                                {props.location.params?.name || job.name}
                            </p>
                            {!loading &&
                                <div
                                    onClick={() => {
                                        navigator.clipboard.writeText(`https://app.recruitu.com/job/${job.id}`);
                                        toast.success("Link copied to clipboard!", TOAST_OPTIONS);
                                    }}
                                    className="px-2 py-2 rounded-3xl bg-primary/20 flex flex-row items-center justify-center hover:opacity-50 cursor-pointer mx-3"
                                >
                                    <LinkIcon className="w-5 h-5 text-primary"/>
                                </div>
                            }
                        </div>
                        {!loading &&
                            <div className='flex text-sm flex-row items-center gap-2'>
                                <p className=' text-slate-500'>
                                    {!!job.city?.length && !!job.state?.length ? `${job.city}, ${job.state} ~` : null} {!!job.type ? job.type + " ~" : null}
                                </p>
                                <div className='flex flex-row text-slate-500 gap-2'>
                                    <p>
                                        Posted: <span
                                        className='text-slate-600'>{DateTime.fromISO(job.created_at).toFormat('MMM dd, yyyy')}{!!job.deadline && ' ~'}</span>
                                    </p>
                                    {DateTime.fromISO(job.deadline).isValid ?
                                        <p>
                                            Closes: <span
                                            className='text-slate-600'>{DateTime.fromISO(job.deadline).toFormat('MMM dd, yyyy')}</span>
                                        </p>
                                        : null
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        <div className='flex flex-col items-end gap-3'>
                            <div className={'flex flex-row gap-3'}>
                                <Button variant={'secondary'} onClick={downloadCsv}>
                                    Download CSV
                                </Button>
                                <RecruiterBulkCreateList studentIds={Array.from(selectedApplications)}
                                                         originalName={job.name}/>
                            </div>
                            <div className={'flex flex-row gap-3'}>
                                <Button variant={'secondary'} onClick={toggleEditJobModal}>
                                    Edit Job Post
                                </Button>
                                <Button variant={'secondary'} onClick={previewJob}>
                                    Preview Job
                                </Button>
                                <Button variant={'destructive'} onClick={deleteStudentJob} className='w-[106px]'>
                                    Delete Job
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'flex flex-col gap-2 flex-1 overflow-hidden'}>
                <div className='flex-1 flex flex-col overflow-hidden'>
                    <div className='flex-1 flex flex-row gap-3 overflow-hidden'>
                        <div className={'w-[250px] flex flex-col'}>
                            <FiltersSidebar
                                search={{
                                    placeholder: 'Search by name...',
                                    value: search,
                                    onChange: handleSearchChange,
                                }}
                                overrideSchoolInput={
                                    <div className="relative">
                                        <WrappedTextInput
                                            type="text"
                                            placeholder={'Search by school...'}
                                            className='pl-44 input-light px-3 body2 w-full'
                                            value={schoolSearch}
                                            onChange={handleSchoolSearchChange}
                                            icon={<SvgSchool width={20} height={20}
                                                             className={'absolute text-slate-500'}
                                                             style={{left: 12, top: 10}}/>}
                                        />
                                        <div className='absolute top-10 left-0 right-0 z-[99]'>
                                            <CollapseComponent
                                                isOpen={schoolSearch.length !== 0 && openSchoolCollapse}
                                                className='bg-white shadow-lg border border-neutral-200 rounded-lg'>
                                                {schoolSearchResults.map((res) =>
                                                    <div
                                                        onClick={() => {
                                                            handleCollegeChange(res.name);
                                                            setSchoolSearch(res.name);
                                                            setOpenSchoolCollapse(false);
                                                        }}
                                                        className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-lg px-2'>
                                                        <div className="h-10 aspect-square border border-slate-200 bg-white rounded-lg object-contain">
                                                            <SchoolLogo image={res.logo_url}/>
                                                        </div>                                                       
                                                        <div className='flex flex-col'>
                                                            <p className='body2 text-slate-800 line-clamp-2'>
                                                                {res.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </CollapseComponent>
                                        </div>
                                    </div>
                                }
                                overrideMajorSelect={
                                   
                                        
                                    <MajorAdvancedSelector
                                        majors={props.constants.major_categories}
                                        selectedMajors={major}
                                        setSelectedMajors={handleMajorChange}
                                    />
                                   
                                }
                                classYearSelect={{
                                    label: 'Class Year',
                                    value: year,
                                    setValue: handleYearChange,
                                    options: [{title: "2025",}, {title: "2026",}, {title: "2027",}, {title: "2028",}],
                                    multiSelect: true,
                                }}
                                gpaSelect={{
                                    label: 'Min GPA',
                                    value: gpa,
                                    setValue: handleGpaChange,
                                    options: [{title: "3.9",}, {title: "3.8",}, {title: "3.7",}, {title: "3.6",}, {title: "3.5",}, {title: "3.4",}, {title: "3.3",}, {title: "3.2",}, {title: "3.1",}, {title: "3.0",}],
                                }}
                                genderSelect={{
                                    label: 'Gender',
                                    value: gender,
                                    setValue: updateDiversity,
                                    options: [{title: "Male",}, {title: "Female",}, {title: "Prefer Not to Specify",}],
                                    field: 'gender'
                                }}
                                overrideRaceSelect={
                                    <MultiSearchRace
                                        selectedValues={race}
                                        setSelectedValues={updateRace}
                                    />
                                }
                                checkboxes={[
                                    {
                                        label: 'Veteran',
                                        active: veteran,
                                        onClick: () => updateDiversity("veteran", !veteran)
                                    },
                                    {
                                        label: 'LGBTQ',
                                        active: lgbtq,
                                        onClick: () => updateDiversity("lgbtq", !lgbtq)
                                    },
                                    {
                                        label: 'Disability',
                                        active: disabled,
                                        onClick: () => updateDiversity("disabled", !disabled)
                                    },
                                    {
                                        label: 'Authorized to work',
                                        active: authorized,
                                        onClick: () => updateDiversity("authorized", !authorized)
                                    }
                                ]}
                            />
                        </div>
                        <div className='flex flex-col overflow-hidden flex-1'>
                            <div className={' py-2 text-lg font-semibold'}>
                                {filteredApplications.length} applications
                            </div>
                            <RecruiterDashboardTable
                                columns={APPLICANTS_TABLE_HEADERS_FULLPAGE}
                                hasCheckbox={true}
                                checked={selectAllChecked}
                                toggleCheckbox={toggleSelectAll}
                                onSort={handleSort}
                                sortStates={sortStates}
                                TableBody={
                                    loading ?
                                        <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                                            <Spinner/>
                                        </div>
                                        :
                                        <div className='flex flex-col gap-2 p-5 pt-2 flex-1 overflow-y-scroll'>
                                            {applications.length === 0 ?
                                                <div
                                                    className='flex flex-col items-center justify-center text-slate-500 text-sm flex-1'>
                                                    No applicants to show
                                                </div>
                                                :
                                                filteredApplications.map(item =>
                                                    <RecruiterJobApplicantTableItem
                                                        item={item}
                                                        columns={APPLICANTS_TABLE_HEADERS_FULLPAGE}
                                                        isSelected={selectedApplications.has(item.id)}
                                                        onSelectStudentChange={handleSelectStudentChange}
                                                    />
                                                )}
                                        </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <QualifiedOnlyProvider>
                <RecruiterEditJobModal
                    job={job}
                    isOpen={editJobModalOpen}
                    closeModal={toggleEditJobModal}
                    authUser={props.authUser}
                    history={props.history}
                    constants={props.constants}
                />
            </QualifiedOnlyProvider>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterJobListingPage);
