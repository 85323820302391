import React from "react";
import { connect } from "react-redux";
import QualifiedOnlyOptions from "./QualifiedOnlyOptions";

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
  };
}

function JobEventQualifications({
  event,
  hideTutorial,
  qualifiedOnly,
  handleQualifiedOnlyChange,
}) {

  const qualifiedOptions = [
    {
      label: "Exclusive",
      value: "qualified",
      description: `Only the selected recipients can apply to this ${
        event ? "event" : "job"
      }`,
    },
    {
      label: "Everyone",
      value: "recommended",
      description: `Anyone can apply to this job`,
    },
  ];


  return (
    <div className="flex flex-col gap-5" >
      {!hideTutorial && (
        <p className="text-lg text-slate-800 font-semibold">
          {`Choose who to share this ${event ? "event" : "job"} with`}
        </p>
      )}
      <>
        <p className="text-md text-slate-500 font-semibold text-slate-800">
          How would you like to share your{" "}
          {event ? "event listing" : "job posting"}?
        </p>
        <fieldset className="grid grid-cols-2 gap-4">
          {qualifiedOptions.map((option) => (
            <div key={option.value} className="h-[94px]">
              <input
                type="radio"
                name="locationOption"
                value={option.value}
                id={option.value}
                className="peer hidden [&:checked_+_label_svg]:block"
                checked={
                  option.value === "qualified" ? qualifiedOnly : !qualifiedOnly
                }
                onChange={() =>
                  handleQualifiedOnlyChange(option.value === "qualified")
                }
              />
              <label
                htmlFor={option.value}
                className={`block cursor-pointer rounded-lg border h-full p-3 text-sm font-medium shadow-sm ${
                  (option.value === "qualified" && qualifiedOnly) ||
                  (option.value === "recommended" && !qualifiedOnly)
                    ? "border-blue-500 bg-blue-50/50"
                    : "border-gray-100 hover:border-gray-200"
                }`}
              >
                <div className="flex items-center justify-between gap-2">
                  <div className="flex flex-col gap-2">
                    <p className="text-md text-slate-800 font-semibold">
                      {option.label}
                    </p>
                    <p className="text-sm text-slate-500">
                      {option.description}
                    </p>
                  </div>
                  <div className="w-6 h-6">
                    <svg
                      className={`h-5 w-5 text-blue-600 ${
                        (option.value === "qualified" && qualifiedOnly) ||
                        (option.value === "recommended" && !qualifiedOnly)
                          ? "block"
                          : "hidden"
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </label>
            </div>
          ))}
        </fieldset>
      </>
      {qualifiedOnly && <QualifiedOnlyOptions />}
      
    </div>
  );
}

export default connect(mapStateToProps)(JobEventQualifications);
