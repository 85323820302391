import React, { useState, useEffect } from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import CreateJobDetailsStep from "./create-job/CreateJobDetailsStep";
import CreateJobDateLocationStep from "./create-job/CreateJobDateLocationStep";
import JobEventQualifications from "./create-event-job/JobEventQualifications";
import { editJob } from "../../api/recruiter/jobs";
import SectionTabs from "../../components/atoms/SectionTabs";
import { toast } from "react-toastify";
import { EditorState } from "draft-js";
import { convertFromHtml, DefaultEditorState } from "../../utils/richEditor";
import { stateToHTML } from "draft-js-export-html";
import AlertModal from "../../components/atoms/modals/AlertModal";
import { sanitizeHtml } from "../../utils/strings";
import { useQualifiedOnly } from "../../contexts/QualifiedOnlyContext";
import { getQualificationParams } from "../../utils/getQualificationsParams";

const ZERO_STATE = {
  activeTab: "Job Details",
  jobTitle: "",
  jobDescription: DefaultEditorState,
  industry: null,
  jobType: null,
  workEnvironment: "On-site",
  city: "",
  state: "",
  directOnRecruit: true,
  postToday: true,
  postOn: null,
  hasDeadline: false,
  deadline: null,
  qualifiedOnly: false,
  openings: null,
  scope: "public",
  jobPdfPath: null,
};

const RecruiterEditJobModal = (props) => {
  const [state, setState] = useState(ZERO_STATE);
  const { initQualifiedOnlyState } = useQualifiedOnly();
  const { filters, selectionMode, lists } = useQualifiedOnly();

  const submit = async () => {
    const params = {
      name: state.jobTitle,
      description: sanitizeHtml(
        stateToHTML(state.jobDescription.getCurrentContent())
      ),
      type: state.jobType,
      city: !!state.city ? [state.city] : [],
      state: !!state.state ? [state.state] : [],
      work_model: state.workEnvironment,
      link: state.url,
      industry: state.industry,
      apply_direct: state.directOnRecruit,
      deadline: state.hasDeadline ? state.deadline : "",
      qualified_only: state.qualifiedOnly,
      openings: state.openings,
      job_pdf_path: state.jobPdfPath,
      ...getQualificationParams({filters, lists, selectionMode, qualifiedOnly: state.qualifiedOnly}),
    };
    toast.promise(
      editJob(props.authUser, props.job.id, params).then(() => {
        window.location.reload();
      }),
      {
        pending: "Saving changes...",
        success: "Changes saved successfully!",
        error: "Failed to save changes.",
      }
    );
  };

  const archive = async () => {
    const params = {
      scope: "expired",
    };
    toast.promise(
      editJob(props.authUser, props.job.id, params).then(() => {
        window.location.reload();
      }),
      {
        pending: "Archiving job...",
        success: "Job archived successfully!",
        error: "Failed to archive job.",
      }
    );
  };

  const unarchive = async () => {
    const params = {
      scope: "public",
    };
    toast.promise(
      editJob(props.authUser, props.job.id, params).then(() => {
        window.location.reload();
      }),
      {
        pending: "Unarchiving job...",
        success: "Job unarchived successfully!",
        error: "Failed to unarchive job.",
      }
    );
  };

  useEffect(() => {
    setInfo();
  }, [props.job]);

  const setInfo = () => {
    const job = props.job || {};
    setState({
      ...ZERO_STATE,
      jobTitle: job.name,
      jobDescription: EditorState.createWithContent(
        convertFromHtml(job.description)
      ),
      jobType: job.type,
      city: !!job.city ? job.city[0] : "",
      state: !!job.state ? job.state[0] : "",
      workEnvironment: job.work_model,
      url: job.link,
      industry: job.industry,
      directOnRecruit: job.apply_direct,
      hasDeadline: !!job.deadline,
      deadline: !!job.deadline ? new Date(job.deadline) : null,
      qualifiedOnly: job.qualified_only,
      openings: job.openings,
      scope: job.scope,
      jobPdfPath: job.job_pdf_path ?? null,
    });
    initQualifiedOnlyState(job);
  };

  const handleInputChange = (e, inputName) => {
    setState({ ...state, [inputName]: e.target.value });
  };

  const handleSelectChange = (inputName, value) => {
    setState({ ...state, [inputName]: value });
  };

  const handleWorkEnvironmentChange = (event) => {
    setState({ ...state, workEnvironment: event.target.value });
  };

  const handleQualifiedOnlyChange = (boolean) => {
    setState({ ...state, qualifiedOnly: boolean });
  };

  const toggleInput = (stateKey) => {
    setState({ ...state, [stateKey]: !state[stateKey] });
  };

  const handleDateChange = (e, stateKey) => {
    setState({ ...state, [stateKey]: e });
  };

  const handleQualificationCheckbox = (inputName) => {
    setState({ ...state, [inputName]: !state[inputName] });
  };

  const changeTab = (tab) => {
    setState({ ...state, activeTab: tab });
  };

  const setEditorState = (editorState) => {
    setState({ ...state, jobDescription: editorState });
  };

  const onJobPdfUpload = (path) => {
    setState({ ...state, jobPdfPath: path });
  };

  const TABS = ["Job Details", "Date and Location", "Candidate Qualification"];

  return (
    <ModalComponent
      isOpen={props.isOpen}
      backgroundColor={"white"}
      header={"Edit This Job"}
      headerToggle
      size={"lg"}
      toggle={props.closeModal}
      FooterComponent={
        <div className="flex flex-row items-between gap-3">
          <div
            onClick={props.closeModal}
            className="secondary-button body1-bold"
          >
            Cancel
          </div>
          <AlertModal
            title={state.scope === "expired" ? "Unarchive Job" : "Archive Job"}
            description={
              state.scope === "expired"
                ? "Are you sure you want to unarchive this job?"
                : "Are you sure you want to archive this job?"
            }
            onConfirm={state.scope === "expired" ? unarchive : archive}
          >
            <div
              onClick={state.scope === "expired" ? unarchive : archive}
              className="secondary-button body1-bold"
            >
              {state.scope === "expired" ? "Unarchive" : "Archive"}
            </div>
          </AlertModal>
          <div onClick={submit} className="primary-button body1-bold">
            Save Changes
          </div>
        </div>
      }
      footerAlignment={"right"}
      showScrollBar
    >
      <div id={"add-joblisting-modal"}>
        <div className="mb-5">
          <SectionTabs
            activeTab={state.activeTab}
            setActiveTab={changeTab}
            tabs={TABS}
          />
        </div>
        {state.activeTab === TABS[0] && (
          <CreateJobDetailsStep
            hideTutorial
            jobTitle={state.jobTitle}
            jobDescription={state.jobDescription}
            jobType={state.jobType}
            industry={state.industry}
            openings={state.openings}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            constants={props.constants}
            setEditorState={setEditorState}
            editorState={state.jobDescription}
            jobId={props.job.id}
            jobPdfPath={state.jobPdfPath}
            onJobPdfUpload={onJobPdfUpload}
          />
        )}
        {state.activeTab === TABS[1] && (
          <CreateJobDateLocationStep
            hideTutorial
            jobTitle={state.jobTitle}
            jobDescription={state.jobDescription}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            workEnvironment={state.workEnvironment}
            city={state.city}
            state={state.state}
            toggleInput={toggleInput}
            directOnRecruit={state.directOnRecruit}
            postToday={state.postToday}
            postOn={state.postOn}
            hasDeadline={state.hasDeadline}
            deadline={state.deadline}
            handleDateChange={handleDateChange}
            handleWorkEnvironmentChange={handleWorkEnvironmentChange}
          />
        )}
        {state.activeTab === TABS[2] && (
          <JobEventQualifications
            hideTutorial
            qualifiedOnly={state.qualifiedOnly}
            handleQualifiedOnlyChange={handleQualifiedOnlyChange}
            handleQualificationCheckbox={handleQualificationCheckbox}
            editing
          />
        )}
      </div>
    </ModalComponent>
  );
};

export default RecruiterEditJobModal;
