import getEnv from "../utils/env";

const FEATURE_FLAGS = {
    LEARN_OPTIMIZATION: true,
    LATERAL_RECRUITER_GOD_MODE: false
};

/**
 * Checks if a feature flag is enabled
 * @param {keyof typeof FEATURE_FLAGS} flagName - The name of the feature flag to check
 * @returns {boolean} - Whether the feature is enabled
 */
export const isFeatureEnabled = (flagName) => {
    const env = getEnv();

    // Override flags based on environment
    if (env === "preview" || env === "development" || env === "local") {
        // Enable all features in development/preview
        return true;
    }
    return FEATURE_FLAGS[flagName] ?? false;
};
