import React, {useState, useEffect} from "react";
import RecruiterDashboardTable from "../dashboard/RecruiterDashboardTable";
import {ATTENDEES_TABLE_HEADERS_FULLPAGE, MAJOR_OPTIONS} from "../../utils/dummy";
import RecruiterEventAttendeeTableItem from "../RecruiterEventAttendeeTableItem";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import GoBack from "../../components/atoms/GoBack";
import {connect} from "react-redux";
import SvgVideo from "../../components/icons/SvgVideo";
import SvgPin from "../../components/icons/SvgPin";
import RecruiterEditEventModal from "../modals/RecruiterEditEventModal";
import {getEvent, getEventAttendees} from "../../api/recruiter/events";
import Spinner from "../../components/Spinner";
import {Button} from "../../components/atoms/Button";
import MessageStudentFlow from "../MessageStudentFlow";
import SvgSchool from "../../components/icons/SvgSchool";
import CollapseComponent from "../../components/atoms/CollapseComponent";
import {toast} from "react-toastify";
import {LinkIcon} from "@heroicons/react/24/outline";
import {filteredCollegeSearch} from "../../utils/typesense";
import {TOAST_OPTIONS} from "../../utils/constants";
import RecruiterBulkCreateList from "../RecruiterBulkCreateList";
import FiltersSidebar from "../atoms/FiltersSidebar";
import MultiSearchRace from "../../components/search/MultiSearchRace";
import SchoolLogo from "../../components-recruiter/atoms/SchoolLogo";
import MajorAdvancedSelector from "../atoms/MajorAdvancedSelector";
import { QualifiedOnlyProvider } from "../../contexts/QualifiedOnlyContext";



function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        candidates: state.userReducer.candidates,
        constants: state.userReducer.constants,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setCandidates: (candidates) => {
            dispatch({
                type: "SET_CANDIDATES",
                candidates,
            })
        },
    }
}

function RecruiterEventListingPage(props) {
    const [attendees, setAttendees] = useState([]);
    const [year, setYear] = useState([]);
    const [major, setMajor] = useState(new Set());
    const [loading, setLoading] = useState(true);
    const [editEventModalOpen, setEditEventModalOpen] = useState(false);
    const [event, setEvent] = useState({});
    const [collegeOptions, setCollegeOptions] = useState([]);
    const [college, setCollege] = useState([]);
    const [gpa, setGpa] = useState("");
    const [schoolSearch, setSchoolSearch] = useState("");
    const [openSchoolCollapse, setOpenSchoolCollapse] = useState(false);
    const [schoolSearchResults, setSchoolSearchResults] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectedAttendees, setSelectedAttendees] = useState(new Set());
    const [search, setSearch] = useState("");
    const [race, setRace] = useState([]);
    const [gender, setGender] = useState("");
    const [veteran, setVeteran] = useState(false);
    const [lgbtq, setLgbtq] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [sortStates, setSortStates] = useState({
        name: 0,
        rsvp_at: 1
    });


    const candidates = React.useMemo(() => Object.values(props.candidates) || [], [props.candidates]);

    const filteredAttendees = React.useMemo(() => {
        const sortedCandidates = candidates.filter(user => {
            let nameMatch = true;
            if (!!search) {
                const fullName = user.first_name + " " + user.last_name;
                const lowerCaseName = fullName.toLowerCase();
                nameMatch = lowerCaseName.startsWith(search.toLowerCase());
            }
            let majorMatch = true;
            if (major.size > 0) {
                majorMatch = user.major.some(m => major.has(m));
            }
            let yearMatch = true;
            if (!!year.length) {
                yearMatch = year.includes(user.grade);
            }
            let collegeMatch = true;
            if (!!college.length) {
                collegeMatch = college.includes(user.college);
            }
            let gpaMatch = true;
            if (!!gpa) {
                gpaMatch = user.gpa >= gpa;
            }
            let raceMatch = true;
            if (!!race.length) {
                raceMatch = user.diversity?.race?.some(r => race.includes(r));
            }
            let genderMatch = true;
            if (!!gender) {
                genderMatch = user.diversity?.gender === gender;
            }
            let veteranMatch = true;
            if (!!veteran) {
                veteranMatch = user.diversity?.veteran === "Yes";
            }
            let lgbtqMatch = true;
            if (!!lgbtq) {
                lgbtqMatch = user.diversity?.lgbtq === "Yes";
            }
            let disabledMatch = true;
            if (!!disabled) {
                disabledMatch = user.diversity?.disabled === "Yes";
            }
            let authorizedMatch = true;
            if (!!authorized) {
                authorizedMatch = user.diversity?.authorized === "Yes";
            }
            return nameMatch && majorMatch && yearMatch && collegeMatch && gpaMatch && attendees.includes(user.username) && raceMatch && genderMatch && veteranMatch && lgbtqMatch && disabledMatch && authorizedMatch;
        });

        const activeSortKey = Object.entries(sortStates).find(([_, value]) => value > 0)?.[0];
        if (activeSortKey) {
            sortedCandidates.sort((a, b) => {
                let comparison;
                if (activeSortKey === 'name') {
                    const aName = `${a.first_name} ${a.last_name}`;
                    const bName = `${b.first_name} ${b.last_name}`;
                    comparison = aName.localeCompare(bName);
                    return sortStates[activeSortKey] === 1 ? comparison : -comparison;
                } else if (activeSortKey === 'rsvp_at') {
                    comparison = new Date(b.rsvp_at || 0) - new Date(a.rsvp_at || 0);
                    return sortStates[activeSortKey] === 1 ? comparison : -comparison;
                }
            });
        }

        return sortedCandidates;
    }, [candidates, search, major, year, college, gpa, attendees, race, gender, veteran, lgbtq, disabled, authorized, sortStates]);

    useEffect(() => {
        setInfo();
    }, [props.location.params?.eventId]);

    const setInfo = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const eventId = lastArg.split("?")[0];

        const res = await getEvent(props.authUser, eventId);
        const result = await getEventAttendees(props.authUser, eventId);
        props.setCandidates(result.students);

        const colleges = new Set(result.students.filter(student => !!student.college).map(student => student.college));
        const lis = Array.from(colleges).sort();
        setEvent(res.event);
        setAttendees(result.students.map(student => student.id));
        setCollegeOptions(lis.map(college => ({title: college})));
        setLoading(false);
    };

    const deleteEvent = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const eventId = lastArg.split("?")[0];
        await deleteEvent(props.authUser, eventId);
        props.history.goBack()
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSchoolSearchChange = async (event) => {
        const text = event.target.value;
        if (!text) {
            setSchoolSearch(text);
            setOpenSchoolCollapse(false);
            handleCollegeChange(null);
            return;
        }
        setSchoolSearch(text);
        setOpenSchoolCollapse(true);
        const results = await filteredCollegeSearch(text);
        setSchoolSearchResults(results);
    };

    const handleYearChange = (yearValue) => {
        if (yearValue === null) {
            setYear([]);
            return;
        }

        let newClassYears = [...year];
        if (year.includes(yearValue)) {
            const index = year.indexOf(yearValue);
            newClassYears.splice(index, 1);
        } else {
            newClassYears.push(yearValue);
        }
        setYear(newClassYears);
    };

    const handleMajorChange = (newMajorSet) => {
        setMajor(newMajorSet);
    };

    const handleGpaChange = (gpaValue) => {
        setGpa(gpaValue);
    };

    const handleCollegeChange = (selectedCollege) => {
        let updatedCollege;
        if (selectedCollege === null) {
            updatedCollege = [];
        } else {
            updatedCollege = [selectedCollege];
        }
        setCollege(updatedCollege);
    };

    const toggleEditEventModal = () => {
        setEditEventModalOpen(!editEventModalOpen);
    };

    const downloadCsv = () => {
        const candidates = Object.values(props.candidates) || [];
        const attendeesList = candidates.filter(user => attendees.includes(user.username));
        const end = attendeesList.map(row => `${row.first_name} ${row.last_name},${row.email},${row.grade},${row.college},${row.gpa},${row?.major.join("/")},${row?.minor?.join("|")},${row?.diversity?.authorized},${row.diversity?.disabled},${row.diversity?.first_generation},${row.diversity?.gender},${row.diversity?.race?.join("|")},${row.diversity?.require_sponsorship},${row.diversity?.veteran}`).join('\n');

        const csv = `name,email,class year,college,gpa,majors,minors,authorized to work,disability,first generation,gender,race,requires sponsorship,veteran\n` + end;
        const blob = new Blob([csv], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'attendees.csv');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const previewEvent = () => {
        props.history.push(`/event/${event.id}`);
    };

    const handleSelectStudentChange = (student) => {
        const newSelectedAttendees = new Set(selectedAttendees);
        if (newSelectedAttendees.has(student.id)) {
            newSelectedAttendees.delete(student.id);
        } else {
            newSelectedAttendees.add(student.id);
        }
        setSelectedAttendees(newSelectedAttendees);
    };

    const toggleSelectAll = () => {
        const newState = !selectAllChecked;
        setSelectAllChecked(newState);
        if (newState) {
            setSelectedAttendees(new Set(filteredAttendees.map(attendee => attendee.id)));
        } else {
            setSelectedAttendees(new Set());
        }
    };

    const updateRace = (race) => {
        setRace(race);
    }

    const updateDiversity = (field, value) => {
        value = value ? value : null;

        if (field === "gender") setGender(value);
        else if (field === "veteran") setVeteran(value);
        else if (field === "lgbtq") setLgbtq(value);
        else if (field === "disabled") setDisabled(value);
        else if (field === "authorized") setAuthorized(value);
    }

    const handleSort = (key) => {
        const newSortStates = { ...sortStates };
        Object.keys(newSortStates).forEach(k => {
            if (k !== key) newSortStates[k] = 0;
        });
        newSortStates[key] = (newSortStates[key] + 1) % 3;
        setSortStates(newSortStates);
    };

    return (
        <div className={`flex flex-col flex-1 p-[12px] overflow-hidden`}>
            <div className='flex flex-col gap-5'>
                <div className='flex flex-row justify-between items-start gap-12'>
                    <div className='flex flex-col gap-1 flex-1'>
                        <GoBack/>
                        <div className="flex flex-row items-center">
                            <p className="text-2xl font-semibold flex items-center">
                                {props.location.params?.name || event.name}
                                {!loading &&
                                    <span
                                        onClick={() => {
                                            navigator.clipboard.writeText(`https://app.recruitu.com/event/${event.id}`);
                                            toast.success("Link copied to clipboard!", TOAST_OPTIONS);
                                        }}
                                        className="ml-2 px-2 py-2 rounded-3xl bg-primary/20 flex items-center justify-center hover:opacity-50 cursor-pointer"
                                    >
                                        <LinkIcon className="w-5 h-5 text-primary"/>
                                    </span>
                                }
                            </p>
                        </div>
                        {!!event.location ?
                            <div className='flex flex-row items-center gap-2 -mt-1 text-slate-500 wrap'>
                                <div className='flex flex-row items-center gap-2'>
                                    {event.location === "Remote" ?
                                        <SvgVideo className={'w-3.5 w-3.5'}/> :
                                        <SvgPin className={'w-5 w-5'}/>}
                                    <p className='text-sm '>
                                        {event.location}
                                    </p>
                                </div>
                                {event.location !== "Remote" && !!event.city && !!event.state ?
                                    <>
                                        <p>
                                            •
                                        </p>
                                        <p className='text-md '>
                                            {event.city[0]}, {event.state[0]}
                                        </p>
                                    </>
                                    : null}
                            </div>
                            :
                            <>
                                {!loading ?
                                    <div className='flex flex-row items-center gap-2 text-slate-500 wrap'>
                                        <div className='flex flex-row items-center gap-1'>
                                            <SvgVideo className={'w-5 w-5'}/>
                                            <p className='text-lg '>
                                                Remote
                                            </p>
                                        </div>
                                    </div>
                                    : null}
                            </>
                        }
                    </div>
                    <div>
                        <div className='flex flex-col items-end gap-3'>
                            <div className={'flex flex-row gap-3'}>
                                <MessageStudentFlow users={candidates} buttonVariant={'default'}/>
                                <Button variant={'secondary'} onClick={downloadCsv}>
                                    Download CSV
                                </Button>
                                <RecruiterBulkCreateList studentIds={Array.from(selectedAttendees)}
                                                         originalName={event.name}/>
                            </div>
                            <div className={'flex flex-row gap-3'}>
                                <Button variant={'secondary'} onClick={toggleEditEventModal}>
                                    Edit Event
                                </Button>
                                <Button variant={'secondary'} onClick={previewEvent}>
                                    Preview Event
                                </Button>
                                <Button variant={'destructive'} onClick={deleteEvent}>
                                    Delete Event
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'flex flex-col flex-1 overflow-hidden'}>
                <div className='flex-1 flex flex-row gap-3 overflow-hidden'>
                    <div className={'w-[250px] flex flex-col'}>
                        <FiltersSidebar
                            search={{
                                placeholder: 'Search by name...',
                                value: search,
                                onChange: handleSearchChange,
                            }}
                            overrideSchoolInput={
                                <div className="relative">
                                    <WrappedTextInput
                                        type="text"
                                        placeholder={'Search by school...'}
                                        className='pl-44 input-light px-3 body2 w-full'
                                        value={schoolSearch}
                                        onChange={handleSchoolSearchChange}
                                        icon={<SvgSchool width={20} height={20}
                                                         className={'absolute text-slate-500'}
                                                         style={{left: 12, top: 10}}/>}
                                    />
                                    <div className='absolute top-10 left-0 right-0 z-[99]'>
                                        <CollapseComponent
                                            isOpen={schoolSearch.length !== 0 && openSchoolCollapse}
                                            className='bg-white shadow-lg border border-neutral-200 rounded-lg'>
                                            {schoolSearchResults.map((res) =>
                                                <div
                                                    onClick={() => {
                                                        handleCollegeChange(res.name);
                                                        setSchoolSearch(res.name);
                                                        setOpenSchoolCollapse(false);
                                                    }}
                                                    className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-lg px-2'>
                                                    <div className="h-10 aspect-square border border-slate-200 bg-white rounded-lg object-contain">
                                                        <SchoolLogo image={res.logo_url}/>
                                                    </div>     
                                                    <div className='flex flex-col'>
                                                        <p className='body2 text-slate-800 line-clamp-2'>
                                                            {res.name}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </CollapseComponent>
                                    </div>
                                </div>
                            }
                            overrideMajorSelect={
                                <MajorAdvancedSelector
                                    majors={props.constants.major_categories}
                                    selectedMajors={major}
                                    setSelectedMajors={handleMajorChange}
                                />
                            }
                            classYearSelect={{
                                label: 'Class Year',
                                value: year,
                                setValue: handleYearChange,
                                options: [{title: "2025",}, {title: "2026",}, {title: "2027",}, {title: "2028",}],
                                multiSelect: true,
                            }}
                            gpaSelect={{
                                label: 'Min GPA',
                                value: gpa,
                                setValue: handleGpaChange,
                                options: [{title: "3.9",}, {title: "3.8",}, {title: "3.7",}, {title: "3.6",}, {title: "3.5",}, {title: "3.4",}, {title: "3.3",}, {title: "3.2",}, {title: "3.1",}, {title: "3.0",}],
                            }}
                            genderSelect={{
                                label: 'Gender',
                                value: gender,
                                setValue: updateDiversity,
                                options: [{title: "Male",}, {title: "Female",}, {title: "Prefer Not to Specify",}],
                                field: 'gender'
                            }}
                            overrideRaceSelect={
                                <MultiSearchRace
                                    selectedValues={race}
                                    setSelectedValues={updateRace}
                                />
                            }
                            checkboxes={[
                                {
                                    label: 'Veteran',
                                    active: veteran,
                                    onClick: () => updateDiversity("veteran", !veteran)
                                },
                                {
                                    label: 'LGBTQ',
                                    active: lgbtq,
                                    onClick: () => updateDiversity("lgbtq", !lgbtq)
                                },
                                {
                                    label: 'Disability',
                                    active: disabled,
                                    onClick: () => updateDiversity("disabled", !disabled)
                                },
                                {
                                    label: 'Authorized to work',
                                    active: authorized,
                                    onClick: () => updateDiversity("authorized", !authorized)
                                }
                            ]}
                        />
                    </div>
                    <div className='flex flex-col overflow-hidden flex-1'>
                        <div className={' py-2 text-lg font-semibold'}>
                            {filteredAttendees.length} attendees
                        </div>
                        <RecruiterDashboardTable
                            hasCheckbox={true}
                            checked={selectAllChecked}
                            toggleCheckbox={toggleSelectAll}
                            columns={ATTENDEES_TABLE_HEADERS_FULLPAGE}
                            onSort={handleSort}
                            sortStates={sortStates}
                            TableBody={
                                loading ?
                                    <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                                        <Spinner/>
                                    </div>
                                    :
                                    <div className='flex flex-col gap-2 p-5 pt-2 flex-1 overflow-y-scroll'>
                                        {filteredAttendees.length === 0 ?
                                            <div
                                                className='flex flex-col items-center justify-center text-slate-500 text-sm flex-1'>
                                                No attendees to show
                                            </div>
                                            :
                                            filteredAttendees.map(item =>
                                                <RecruiterEventAttendeeTableItem
                                                    item={item}
                                                    columns={ATTENDEES_TABLE_HEADERS_FULLPAGE}
                                                    onSelectStudentChange={handleSelectStudentChange}
                                                    isSelected={selectedAttendees.has(item.id)}
                                                />
                                            )}
                                    </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <QualifiedOnlyProvider>
                <RecruiterEditEventModal
                    event={event}
                    isOpen={editEventModalOpen}
                    closeModal={toggleEditEventModal}
                    authUser={props.authUser}
                    constants={props.constants}
                />
            </QualifiedOnlyProvider>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterEventListingPage);
