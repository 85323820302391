import axios from "./axios";
import {firebaseAuth} from "./firebase";
import {signInWithEmailAndPassword} from "firebase/auth";

export const signUp = async (email, password, first_name, last_name, referrer, club_id, have_job, job_status) => {
    const body = {
        email,
        password,
        first_name,
        last_name,
        referrer,
        club_id,
        have_job,
        job_status,
    };
    const res = await axios.post("/auth/signup", body);
    return res.data;
};

export const sendVerificationEmail = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken(); 
    const config = {
        headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
    };
    const params = {
        // we add the full url to the params so that we can redirect to the correct page after verification
        full_url: window.location.href,
        origin: window.location.origin
    };
    const res = await axios.post("/auth/send-verification-email", params, config);
    return res.data;
};

export const sendResetPasswordEmail = async (email, recruiter) => {
    const config = {
        headers: {mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
    };
    const params = {
        email,
        recruiter
    };
    const res = await axios.post("/auth/send-password-reset", params, config);
    return res.data;
};

export const signUpRecruiter = async (email, password, first_name, last_name, company_id) => {
    const body = {
        email,
        password,
        first_name,
        last_name,
        company_id
    };
    const res = await axios.post("/auth/recruiter/signup", body);
    return res.data;

}

export const login = async (email, password) => {
    return await signInWithEmailAndPassword(firebaseAuth, email, password)
        .then(async credential => {
            return credential.user
        })
        .catch(async error => {
            if (error.code === 'auth/invalid-email') {
                const uid = email;
                const config = {
                    params: {uid, email, password}
                };
                try {
                    const response = await axios.get("/auth/email", config);
                    return await login(response.data.email, password)
                } catch (err) {
                    throw new Error(err.request.status, err.message);
                }
            } else {
                throw new Error(error.code, error.message)
            }
        })
};

export const logout = async () => {
    return await firebaseAuth.signOut()
        .then(() => true)
        .catch(error => {
            console.log(error.code, error.message);
            return false
        })
};

export const disableAccount = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/auth/disable", {}, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
};


export const checkInvitedEmail = async (email) => {
    const config = {
        params: {email}
    };
    const res = await axios.get("/auth/recruiter/signup", config);
    return res.data;
}