import { NUM_CLUBS_SHOWN, NUM_STUDENTS_SHOWN, NUM_COLLEGES_SHOWN, NUM_PEOPLE_SHOWN, CURRENT_YEAR, NUM_MAJORS_SHOWN } from '../utils/constants';

const Typesense = require('typesense')
let client = new Typesense.Client({
    'nodes': [{
        'host': 'hrgke1ijqctn8uovp-1.a1.typesense.net',
        'port': '443',
        'protocol': 'https'
    }],
    'apiKey': 'H8mwxVn0VNLpfQ5mZejr9MHElB44GrLQ',
    'connectionTimeoutSeconds': 2
})

export const jobsIndex = client.collections('jobs').documents();
export const companiesIndex = client.collections('companies').documents();
export const eventsIndex = client.collections('events').documents();
export const clubsIndex = client.collections('clubs').documents();
export const usersIndex = client.collections('users').documents();
export const collegesIndex = client.collections('colleges').documents();
export const peopleIndex = client.collections('people').documents();
export const questionsIndex = client.collections('questions').documents();
export const smartListsIndex = client.collections('smart_lists').documents();
export const clubListsIndex = client.collections('club_lists').documents();
export const userListsIndex = client.collections('user_lists').documents();
export const majorsIndex = client.collections('majors').documents();

export const SEGMENT_QUERY_KEYSTROKE_TIMEOUT_LENGTH = 1000; // 1s




let searchCompaniesSegmentInterval = null;
export const searchCompanies = async (query, per_page=3, page=1, industry=[], cities=[], skipSegment=true) => {
    let filter = 'demo:!=true && searchable:=true'
    if (industry.length > 0) {
        const industry_string = JSON.stringify(industry)
        filter += ` && (sector:=${industry_string} || specialties:=[${industry_string}])`;
    }
    if (cities.length > 0) {
        const cities_string = JSON.stringify(cities)
        filter += ` && cities:=[${cities_string}]`;
    }

    // segment tracking
    clearTimeout(searchCompaniesSegmentInterval);
    if (!skipSegment) {
      const filters = {
        ...industry.length && {industry},
        ...cities.length && {cities},
        ...query && {query},
      }
      if (query !== "") {
        searchCompaniesSegmentInterval = setTimeout(() => {
          window.analytics.track("Company Searched", {
            filters
          })
        }, SEGMENT_QUERY_KEYSTROKE_TIMEOUT_LENGTH)
      } else {
        if (Object.keys(filters).length > 0) {
          window.analytics.track("Company Searched", {
            filters
          })
        }
      }
    }

    const searchParams = {
        q: query,
        query_by: 'name',
        per_page,
        page,
        filter_by: filter,
        sort_by: 'is_partner:desc,ranking:asc',
    }
    const response = await companiesIndex.search(searchParams);
    const companiesResults = response.hits.map((hit) => {
        return hit.document;
    })
    return companiesResults;
}


export const searchClubs = async (query, page=1, per_page=NUM_CLUBS_SHOWN, college=null, from_master_list=false) => {
    let filter = `hidden_from_recruiters:!=true`
    if (!!college && college !== null) {
        const college_string = college.replace("(", "").replace(")", "")
        filter += ` && college:=${college_string.replace("(", "").replace(")", "").replace('\"', '').replace('"', "")}`;
    }
    const searchParams = {
        q: query,
        query_by: 'name,college',
        per_page,
        page,
        filter_by: filter,
        sort_by: 'logo_url:asc',
    }

    const response = await clubsIndex.search(searchParams);
    const clubsResults = response.hits.map((hit) => {
        return hit.document;
    })
    return clubsResults;
}

export const searchClubsById = async (clubIds) => {
    const searchParams = {
        q: "",
        query_by: "name",
        filter_by: `id:=[${clubIds}]`,
        per_page: 250,
    }
    const response = await clubsIndex.search(searchParams);
    return response.hits.map((hit) => hit.document);
}

export const searchColleges = async (query, page=1) => {
    const country = "[United States,Canada]";
    const searchParams = {
        q: query,
        query_by: 'name,all_names,alternate_name',
        per_page: NUM_COLLEGES_SHOWN,
        page: page,
        filter_by: `country:=${country}`,
        sort_by: 'logo_url:asc',

    }
    const response = await collegesIndex.search(searchParams);
    const collegesResults = response.hits.map((hit) => {
        return hit.document;
    })
    return collegesResults;
}

export const filteredCollegeSearch = async (query, page=1) => {
    const country = "[United States,Canada]";
    const searchParams = {
        q: query,
        query_by: 'name,all_names,alternate_name',
        per_page: 5,
        page: page,
        filter_by: `country:=${country}`,
        sort_by: 'logo_url:asc',
    }
    const response = await collegesIndex.search(searchParams);
    const collegesResults = response.hits.map((hit) => {
        return hit.document;
    })
    return collegesResults;
}

export const searchMajors = async (query, page=1) => {
    const searchParams = {
        q: query,
        query_by: 'label,category',
        per_page: NUM_MAJORS_SHOWN,
        page,
    }
    const response = await majorsIndex.search(searchParams);
    const majorsResults = response.hits.map((hit) => {
        return hit.document;
    })
    return majorsResults;
}

export const filteredUserSearch = async (
    query,
    page=1,
    clubId=null,
    classYears=[],
    majors=[],
    college=[],
    gpa=null,
    gender=null,
    race=[],
    veteran=null,
    lgbtq=null,
    disabled=null,
    authorized=null,
    industries=[],
    sortBy=null,
    currentCompany=null,
    previousCompany=null,
    isIb=null,
) => {
    let filter = `deactivated:=${false} && claimed:=${true}`; 
    if (clubId !== null) {
        filter += ` && clubs:=[${clubId}]`;
    }
    if (classYears.length > 0) {
        const classYearsString = JSON.stringify(classYears)
        filter += ` && grade:=${classYearsString}`;
    }
    if (majors.length > 0) {
        const majorsString = JSON.stringify(majors)
        filter += ` && major:=${majorsString}`;
    }
    if (college.length > 0) {
        const collegesString = JSON.stringify(college)
        const newString = ` && college:=${collegesString.replace("(", "").replace(")", "").replace('\"', '').replace('"', "")}`;
        if (college.length === 1) {
            filter += newString.replace("[", "").replace("]", "")
        } else {
            filter += newString
        }
    }
    if (industries.length > 0) {
        const industryString = JSON.stringify(industries)
        filter += ` && industries:=${industryString}`;
    }
    if (gpa !== null) {
        filter += ` && gpa:>${gpa}`;
    }
    if (gender !== null) {
        filter += ` && diversity.gender:=${gender}`;
    }
    if (!!race && race.length) {
        const raceString = JSON.stringify(race);
        filter += ` && diversity.race:=[${raceString}]`;
    }
    if (veteran !== null) {
        filter += ` && diversity.veteran:=${veteran ? "Yes" : "No"}`;
    }
    if (lgbtq !== null) {
        filter += ` && diversity.lgbtq:=${lgbtq ? "Yes" : "No"}`;
    }
    if (disabled !== null) {
        filter += ` && diversity.disabled:=${disabled ? "Yes" : "No"}`;
    }
    if (authorized !== null) {
        filter += ` && diversity.authorized:=${authorized ? "Yes" : "No"}`;
    }
    if (isIb !== null) {
        filter += ` && is_ib:=${isIb}`;
    }
    if (currentCompany !== null) {
        filter += ` && current_company_id:=${currentCompany}`;
    }
    if (previousCompany !== null) {
        filter += ` && previous_companies_ids:=[${previousCompany}]`;
    }
    const searchParams = {
        q: query,
        query_by: 'first_name,last_name,college',
        filter_by: filter,
        per_page: NUM_STUDENTS_SHOWN,
        page: page,
    }

    if (!sortBy) {
        searchParams.sort_by = '_eval(onboarded:true):desc,created_at(missing_values: last):desc,resume:desc'
    } else {
        searchParams.sort_by = 'last_name:asc,profile_pic_url:asc,resume:desc'
    }

    const response = await usersIndex.search(searchParams);
    const usersResults = response.hits.map((hit) => {
        return hit.document;
    })
    return {students: usersResults, total: response.found};
}


export const userClubSearch = async (query, clubId, class_year=null, job_status=null, page=1, sort_by=null) => {
    let filter = `deactivated:=${false} && clubs:=[${clubId}] && poc:!=${true}`;
    if (!!class_year) {
        const classYearsString = JSON.stringify([class_year]);
        filter += ` && grade:=${classYearsString}`;
    }
    if (!!job_status) {
        filter += ` && job_status:=${job_status}`;
    }
    const searchParams = {
        q: query,
        query_by: 'first_name,last_name',
        filter_by: filter,
        per_page: NUM_STUDENTS_SHOWN,
        page,
    }

    if (sort_by) {
        searchParams.sort_by = sort_by
    } else {
        searchParams.sort_by = "_eval(onboarded:true):desc"
    }

    const response = await usersIndex.search(searchParams);
    const usersResults = response.hits.map((hit) => {
        return hit.document;
    })
    return {results: usersResults, total: response.found};
}

let filteredPersonSearchQueryInterval = null;
export const filteredPersonSearch = async (query, club_id, page=1, class_year=null, industry=[], source=null, companyId=null, school=null, position=[], skipSegment=true, sort_by=null) => {
    let filter = "";
    const clubString = JSON.stringify(club_id)
    const newString = `club_id:=${clubString.replace("(", "").replace(")", "").replace('\"', '').replace('"', "")}`;
    if (club_id.length === 1) {
        filter += newString.replace("[", "").replace("]", "")
    } else {
        filter += newString
    } 
    
    if (source !== null) {
        filter = `(${filter} || source:=${source})`;
    }
    if (class_year !== null && class_year !== "") {
        // Support two digit year filtering
        if (class_year.length === 2) {
            class_year = (parseInt(class_year) <= CURRENT_YEAR) ? `20${class_year}` : `19${class_year}`;
        }
        filter += ` && grade:=${class_year}`;
    }
    if (industry.length > 0) {
        filter += ` && industry:=${JSON.stringify(industry)}`;
    }
    if (position.length > 0) {
        filter += ` && title:=${JSON.stringify(position)}`
    }
    if (companyId !== null) {
        filter += ` && company_id:=${companyId}`
    }
    if (school !== null) {
        const school_string = school.replace("(", "").replace(")", "")
        filter += ` && school:=${school_string}`
    }
    const jsonFilters = filter.split(" && ").slice(1).reduce((obj, e) => {
      let s = e;
      if (e[0] === '(')
        s = e.slice(1, e.length - 1)
      const k = s.split(":=")[0];
      const v = s.split(":=")[1];
      return {...obj, [k]: v}
    }, {})

    // segment tracking
    clearTimeout(filteredPersonSearchQueryInterval)
    if (!skipSegment) {
      if (query !== "") {
        filteredPersonSearchQueryInterval = setTimeout(() => {
          window.analytics.track("Contacts Searched", {
            filters: {
              ...jsonFilters,
              query
            },
          })
        }, SEGMENT_QUERY_KEYSTROKE_TIMEOUT_LENGTH)
      } else {
        if (Object.keys(jsonFilters).length > 0)
          window.analytics.track("Contacts Searched", {
            filters: {...jsonFilters},
          })
      }
    }

    const searchParams = {
        q: query,
        query_by: 'first_name,last_name,company_name',
        filter_by: filter,
        per_page: NUM_PEOPLE_SHOWN,
        page,
    }

    if (sort_by) {
        searchParams.sort_by = sort_by
    }

    const response = await peopleIndex.search(searchParams);
    const peopleResults = response.hits.map((hit) => {
        return hit.document;
    })
    return {results: peopleResults, total: response.found};

}

export const homePagePersonSearch = async (companyIds, school=null) => {
    let filter = `title:=Analyst`;
    if (school !== null) {
        filter += ` && (company_id:=${JSON.stringify(companyIds)} || school:=${school})`
    } else {
        filter += ` && company_id:=${JSON.stringify(companyIds)}`
    }
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = chars.charAt(Math.floor(Math.random() * chars.length));
      
    const searchParams = {
        q: result,
        query_by: 'first_name,last_name,company_name',
        filter_by: filter,
        per_page: 10,
    }
    const response = await peopleIndex.search(searchParams);
    const peopleResults = response.hits.map((hit) => {
        return hit.document;
    })
    return peopleResults;
}

export const searchQuestions = async (query, per_page=10, page=1, industry=[], company_id=null, difficulty=null, category=null) => {
    let filter = '';
    if (!!company_id) {
        filter += `company_id:=${company_id}`;
    }
    if (industry.length > 0) {
        if (!!filter) filter += " && "
        filter += `industry:=${industry}`;
    }
    if (!!difficulty) {
        if (!!filter) filter += " && "
        filter += `difficulty:=${difficulty}`;
    }
    if (!!category) {
        if (!!filter) filter += " && "
        const category_string = category.replace("(", "").replace(")", "")
        filter += `category:=${JSON.stringify(category_string)}`;
    }
    const searchParams = {
        q: query,
        per_page,
        page,
        filter_by: filter,
        query_by: 'name,prompt,company_name',
        sort_by: 'created_at(missing_values: last):desc',
    }
    const response = await questionsIndex.search(searchParams);
    const questionsResults = response.hits
        .map((hit) => {
        return hit.document;
    })
    return questionsResults;
}

export const adminUserSearch = async (
    query,
    page = 1,
    count = NUM_STUDENTS_SHOWN,
    filters = {}
) => {
    let filter = `claimed:=${true}`;

    if (filters.college && filters.college.length > 0) {
        const collegesString = JSON.stringify(filters.college);
        const newString = ` && college:=${collegesString.replace("(", "").replace(")", "").replace('\"', '').replace('"', "")}`;
        if (filters.college.length === 1) {
            filter += newString.replace("[", "").replace("]", "");
        } else {
            filter += newString;
        }
    }

    if (filters.classYears && filters.classYears.length > 0) {
        filter += ` && grade:=${JSON.stringify(filters.classYears)}`;
    }

    if (filters.profileStatus) {
        switch (filters.profileStatus) {
            case "Account Created":
                filter += ` && onboarded:=${false}`;
                break;
            case "Onboarded":
                filter += ` && (onboarded:=${true} && profile_status.full_profile:=incomplete)`;
                break;
            case "Profile Complete":
                filter += ` && profile_status.full_profile:=complete`;
                break;
            case "Archived":
                filter += ` && deactivated:=${true}`;
                break;
            default:
                break;
        }
    }

    if (filters.profileDetails && filters.profileDetails.length > 0) {
        filters.profileDetails.forEach(detail => {
            switch (detail) {
                case "Profile Details":
                    filter += ` && profile_status.profile:=complete`;
                    break;
                case "Professional Experience":
                    filter += ` && profile_status.experience:=complete`;
                    break;
                case "Education Details":
                    filter += ` && profile_status.education:=complete`;
                    break;
                case "Resume":
                    filter += ` && profile_status.resume:=complete`;
                    break;
                case "LinkedIn":
                    filter += ` && profile_status.linkedin:=complete`;
                    break;
                case "Location Preferences":
                    filter += ` && profile_status.location:=complete`;
                    break;
                case "Role Preferences":
                    filter += ` && profile_status.role:=complete`;
                    break;
                case "Company Preferences":
                    filter += ` && profile_status.companies:=complete`;
                    break;
                case "Equal Employment":
                    filter += ` && profile_status.employment:=complete`;
                    break;
                case "Authorization / Sponsorship":
                    filter += ` && profile_status.sponsorship:=complete`;
                    break;
                default:
                    break;
            }
        });
    }

    if (filters.manuallyVerified) {
        filter += ` && manually_verified:=${true}`;
    }

    if (filters.hasJob) {
        filter += ` && have_job:=${true}`;
    }

    if (filters.seekingRole) {
        filter += ` && seeking_role:=${true}`;
    }

    if (filters.viewedSuggestedJobs) {
        filter += ` && viewed_suggested_jobs:=${true}`;
    }


    const searchParams = {
        q: query,
        query_by: 'first_name,last_name,email,username',
        filter_by: filter,
        per_page: count,
        page,
        sort_by: 'created_at:desc'
    }

    const response = await usersIndex.search(searchParams);
    return {
        students: response.hits.map(hit => hit.document),
        total: response.found
    };
}

export const adminPeopleSearch = async (
    query,
    page = 1,
    count = NUM_PEOPLE_SHOWN,
    filters = {}
) => {
    let filter = '';

   
    if (filters.school) {
        if (filter) filter += ' && ';
        filter += `school:=${filters.school}`;
    }

    if (filters.industry && filters.industry.length > 0) {
        if (filter) filter += ' && ';
        filter += `industry:=${JSON.stringify(filters.industry)}`;
    }

    if (filters.position && filters.position.length > 0) {
        if (filter) filter += ' && ';
        filter += `title:=${JSON.stringify(filters.position)}`;
    }

    if (filters.company_id) {
        if (filter) filter += ' && ';
        filter += `company_id:=${filters.company_id}`;
    }

    const searchParams = {
        q: query,
        query_by: 'first_name,last_name,company_name',
        filter_by: filter,
        per_page: count,
        page,
        sort_by: 'created_at:desc'
    }

    const response = await peopleIndex.search(searchParams);
    return {
        people: response.hits.map(hit => hit.document),
        total: response.found
    };
}
