import React, { useState } from "react";

import { Button } from "../atoms/Button";
import AdminStudentManagement from "../../components-admin/AdminStudentManagement";
import AdminPeopleManagement from "../../components-admin/AdminPeopleManagement";
import SectionTabs from "../atoms/SectionTabs";

// Industry Professionals TODO: enable Industry Professionals when ready
const tabs = ["Students"];

const AdminManagement = (props) => {
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [exportEmailCsvLoading, setExportEmailCsvLoading] = useState(false);
    const [exportFullCsvLoading, setExportFullCsvLoading] = useState(false);

    function handleExportCSV(type) {
        if (type === "email") {
            setExportEmailCsvLoading(true);
            window.dispatchEvent(
                new CustomEvent("exportStudentCSV", {
                    detail: { type: "email" },
                })
            );
        } else {
            setExportFullCsvLoading(true);
            window.dispatchEvent(
                new CustomEvent("exportStudentCSV", {
                    detail: { type: "full" },
                })
            );
        }
    }
    return (
        <div className="p-5">
            <div className="flex flex-col gap-3 mb-4 bg-white">
                <div className="flex justify-between items-center">
                    <h1 className="text-slate-800 text-2xl font-semibold">
                        Admin Management
                    </h1>
                    {activeTab === "Students" && (
                        <div className="flex gap-2">
                            <Button
                                loading={exportEmailCsvLoading}
                                disabled={exportEmailCsvLoading}
                                onClick={() => handleExportCSV("email")}
                            >
                                Email Only CSV Export
                            </Button>
                            <Button
                                loading={exportFullCsvLoading}
                                disabled={exportFullCsvLoading}
                                onClick={() => handleExportCSV("full")}
                            >
                                Full CSV Export
                            </Button>
                        </div>
                    )}
                </div>
                <SectionTabs
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    lg
                />
            </div>
            {activeTab === tabs[0] && (
                <AdminStudentManagement
                    setExportEmailCsvLoading={setExportEmailCsvLoading}
                    setExportFullCsvLoading={setExportFullCsvLoading}
                />
            )}
            {/* TODO: enable lateral recruiting when ready */}
            {/* {activeTab === tabs[1] && (
                <AdminPeopleManagement />
            )} */}
        </div>
    );
};

export default AdminManagement;
